<template>
  <div class="container schedule-post-history">
    <h3 class="mt-5 text-center">Scheduled Feed</h3>
    <ul
      class="nav nav-tabs my-3 justify-content-center custom-navs position-relative"
      id="pills-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          @click="setCalendar('Upcoming')"
        >
          Upcoming
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
         @click="setCalendar('Past')"
        >
          Past & Cancelled
        </button>
      </li>   
      <div class="position-absolute end-0 top-0">
        <a class="btn btn-primary mx-2 btn-sm" data-bs-toggle="modal"  data-bs-target="#CalendarModal"  href="javascript:;">
           <i class="fa fa-calendar"></i> Calendar View
        </a>
      </div>  
    </ul>
     
    <div class="tab-content mb-5" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="mt-4 history">
           
          <vue-good-table
            :columns="columns"
            :rows="historyData.upcoming"
            :fixed-header="true"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'scheduled_date', type: 'desc' },
            }"
            :search-options="{ enabled: true, placeholder: 'Search ' }"
            :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,

              rowsPerPageLabel: 'Feeds per page',
              perPageDropdown: [10, 20, 30, 40, 50],
              perPage: 10,
              mode: 'records',
            }"
          >
            <template slot="table-row" slot-scope="props">
              {{
                props.column.field != "mediaUrls" &&
                props.column.field != "platforms" &&
                props.column.field != "post" && 
                props.column.field != "scheduled_date"
                  ? props.formattedRow[props.column.field]
                  : ""
              }}
              {{
                props.column.field == "scheduled_date" && props.formattedRow.scheduled_date ? dateFormat(props.formattedRow.scheduled_date) : ""
              }}
               <div v-if="props.column.field == 'title'">               
                <span class="bg-primary badge ms-1 mt-3" v-if="props.row.is_scheduled == -1"> 
                  Monthly Feed
                </span>
                <span class="bg-primary badge ms-1 mt-3" v-if="props.row.type == 'review'"> 
                  Review
                </span>
              </div>
              <span v-if="props.column.field == 'post'">
                <span v-html="props.formattedRow.post"></span>
              </span>
              <span
                class="d-flex align-items-center gap-1 flex-nowrap"
                v-if="
                  props.column.field == 'platforms' &&
                  props.formattedRow.platforms
                "
              >
                <!-- {{props.formattedRow.mediaUrls.toString()}} -->
                <span
                  v-for="(item, index) of JSON.parse(
                    props.formattedRow.platforms
                  )"
                  :key="index"
                >
                  <!-- <span v-if="item=='facebook'" class="ms-1"><i class="bi bi-facebook text-primary"></i></span> -->
                  <span v-if="item == 'facebook'" v-tooltip="'Facebook Page'"
                    ><i class="bi bi-facebook facebook-icon fs-4"></i
                    >&nbsp;</span
                  >
                  <span v-if="item == 'instagram'" v-tooltip="'Instagram'"
                    ><i class="bi bi-instagram instagram-icon fs-4"></i
                    >&nbsp;</span
                  >
                  <span v-if="item == 'youtube'" v-tooltip="'Youtube'"
                    ><i class="bi bi-youtube youtube-icon fs-4"></i>&nbsp;</span
                  >
                  <span v-if="item == 'twitter'" v-tooltip="'Twitter'"
                    ><i class="bi bi-twitter twitter-icon fs-4"></i>&nbsp;</span
                  >
                  <span v-if="item == 'pinterest'" v-tooltip="'Pinterest'"
                    ><i class="bi bi-pinterest pinterest-icon fs-4"></i
                    >&nbsp;</span
                  >
                  <span v-if="item == 'linkedin'" v-tooltip="'LinkedIn'"
                    ><i class="bi bi-linkedin linkedin-icon fs-4"></i
                    >&nbsp;</span
                  >
                  <span v-if="item == 'gmb'" v-tooltip="'Google My Business'">
                    <i class="bi bi-google google-icon fs-4"></i>&nbsp;</span
                  >
                  <span v-if="item == 'telegram'" v-tooltip="'Telegram'"
                    ><i class="bi bi-telegram telegram-icon fs-4"></i
                    >&nbsp;</span
                  >
                  <span v-if="item == 'tiktok'" v-tooltip="'Tiktok'"
                    ><i class="bi bi-tiktok tiktok-icon fs-4"></i>&nbsp;</span
                  >
                  <span v-if="item == 'fbg'" v-tooltip="'Fecebook Group'">
                    <img
                      src="../../assets/fb-group.png"
                      class="fb-img fs-4"
                      alt=""
                    />&nbsp;</span
                  >
                  <span v-if="item == 'reddit'" v-tooltip="'Reddit'"
                    ><i class="bi bi-reddit reddit-icon fs-4"></i>&nbsp;</span
                  >
                </span>
              </span>
              <span v-if="props.column.field == 'mediaUrls'">
                <!-- {{props.formattedRow.mediaUrls.toString()}} -->
                <a
                  v-if="props.formattedRow.mediaUrls"
                  :href="JSON.parse(props.formattedRow.mediaUrls.toString())"
                  target="_blank"
                  ><img
                    v-if="props.formattedRow.mediaUrls"
                    :src="JSON.parse(props.formattedRow.mediaUrls.toString())"
                    alt=""
                  />
                </a>
              </span>

              <span v-if="props.column.field == 'actions'">
                <button
                  v-if="props.row.scheduled_status == 'Scheduled'"
                  class="btn bg-light shadow-ls border rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalSchedule"
                  @click="openModal(props.row)"
                >
                  Cancel
                </button>
                <span class="bg-primary badge ms-1 mt-3" v-if="props.row.scheduled_status == 'Planned'"> 
                  {{props.row.scheduled_status}}
                </span>
              </span>
            </template>
          </vue-good-table>
          <div class="text-center" v-if="isLoading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModalSchedule"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Cancel Scheduled Feed
                  </h5>
                </div>
                <div class="modal-body">
                  <p>Are you sure, you want to Cancel?</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalSchedule"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    :class="isCancel ? 'disabled' : ''"
                    @click="cancelSchedule"
                  >
                    Yes
                    <span
                      v-if="isCancel"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div class="mt-4 history">          
          <vue-good-table
            :columns="columns1"
            :rows="historyData.past"
            :fixed-header="true"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'scheduled_date', type: 'desc' },
            }"
            :search-options="{ enabled: true, placeholder: 'Search ' }"
            :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,

              rowsPerPageLabel: 'Feeds per page',
              perPageDropdown: [10, 20, 30, 40, 50],
              perPage: 10,
              mode: 'records',
            }"
          >
            <template slot="table-row" slot-scope="props">
              {{
                props.column.field != "mediaUrls" &&
                props.column.field != "scheduled_status" &&
                props.column.field != "platforms" &&
                props.column.field != "post" &&
                props.column.field != "scheduled_date"
                  ? props.formattedRow[props.column.field]
                  : ""
              }}
              {{
                props.column.field == "scheduled_date" && props.formattedRow.scheduled_date ? dateFormat(props.formattedRow.scheduled_date) : ""
              }}
              <div v-if="props.column.field == 'title'">               
                <span class="bg-primary badge ms-1 mt-3" v-if="props.row.is_scheduled == -1"> 
                  Monthly Feed
                </span>
                <span class="bg-primary badge ms-1 mt-3" v-if="props.row.type == 'review'"> 
                  Review
                </span>
              </div>
              <span v-if="props.column.field == 'post'">
                <span v-html="props.formattedRow.post"></span>
              </span>
              <span
                class="d-flex align-items-center gap-1 flex-nowrap"
                v-if="
                  props.column.field == 'platforms' &&
                  props.formattedRow.platforms
                "
              >
                <!-- {{props.formattedRow.mediaUrls.toString()}} -->
                <span
                  v-for="(item, index) of JSON.parse(
                    props.formattedRow.platforms
                  )"
                  :key="index"
                >
                  <!-- <span v-if="item=='facebook'" class="ms-1"><i class="bi bi-facebook text-primary"></i></span> -->
                  <span
                    v-if="item == 'facebook'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty(
                        'facebook'
                      )
                        ? showLink(props.row.post_response[item], item)
                        : 'Facebook Page',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'facebook'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                    ><i class="bi bi-facebook facebook-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span
                    v-if="item == 'instagram'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty(
                        'instagram'
                      )
                        ? showLink(props.row.post_response[item], item)
                        : 'Instagram',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'instagram'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-instagram instagram-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span v-if="item == 'youtube'" v-tooltip="'Youtube'"
                    ><i class="bi bi-youtube youtube-icon fs-4"></i>&nbsp;</span
                  >

                  <span
                    v-if="item == 'twitter'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty('twitter')
                        ? showLink(props.row.post_response[item], item)
                        : 'Twitter',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'twitter'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-twitter twitter-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span
                    v-if="item == 'pinterest'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty(
                        'pinterest'
                      )
                        ? showLink(props.row.post_response[item], item)
                        : 'Pinterest',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'pinterest'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-pinterest pinterest-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span
                    v-if="item == 'linkedin'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty(
                        'linkedin'
                      )
                        ? showLink(props.row.post_response[item], item)
                        : 'LinkedIn',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'linkedin'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-linkedin linkedin-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span
                    v-if="item == 'gmb'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty('gmb')
                        ? showLink(props.row.post_response[item], item)
                        : 'Google My Business',
                      autoHide: props.row.post_response.hasOwnProperty('gmb')
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-google google-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span
                    v-if="item == 'telegram'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty(
                        'telegram'
                      )
                        ? showLink(props.row.post_response[item], item)
                        : 'Telegram',
                      autoHide: props.row.post_response.hasOwnProperty(
                        'telegram'
                      )
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <i class="bi bi-telegram telegram-icon fs-4"></i>
                    &nbsp;</span
                  >

                  <span v-if="item == 'tiktok'" v-tooltip="'Tiktok'"
                    ><i class="bi bi-tiktok tiktok-icon fs-4"></i>&nbsp;</span
                  >

                  <span
                    v-if="item == 'fbg'"
                    v-tooltip="{
                      content: props.row.post_response.hasOwnProperty('fbg')
                        ? showLink(props.row.post_response[item], item)
                        : 'Facebook Group',
                      autoHide: props.row.post_response.hasOwnProperty('fbg')
                        ? false
                        : true,
                      html: true,
                    }"
                  >
                    <img
                      src="../../assets/fb-group.png"
                      class="fb-img fs-4"
                      alt=""
                    />
                    &nbsp;</span
                  >

                  <span v-if="item == 'reddit'" v-tooltip="'Reddit'"
                    ><i class="bi bi-reddit reddit-icon fs-4"></i>&nbsp;</span
                  >
                </span>
              </span>
              <span v-if="props.column.field == 'mediaUrls'">
                <a
                  v-if="props.formattedRow.mediaUrls"
                  :href="JSON.parse(props.formattedRow.mediaUrls.toString())"
                  target="_blank"
                  ><img
                    v-if="props.formattedRow.mediaUrls"
                    :src="JSON.parse(props.formattedRow.mediaUrls.toString())"
                    alt=""
                  />
                </a>
              </span>
              <span v-if="props.column.field == 'scheduled_status'">
                <span
                  v-if="props.formattedRow.scheduled_status == 'Scheduled'"
                  class="badge bg-info"
                >
                  {{ props.formattedRow.scheduled_status }}
                </span>
                <span
                  v-if="props.formattedRow.scheduled_status == 'Success'"
                  class="badge bg-success"
                >
                  {{ props.formattedRow.scheduled_status }}
                </span>
                <span
                  v-tooltip="
                  props.row.post_response ? modifyText(JSON.parse(props.row.post_response).errors[0].message) : ''
                  "
                  v-if="props.formattedRow.scheduled_status == 'Error'"
                  class="badge bg-danger"
                >
                  {{ props.formattedRow.scheduled_status }}
                </span>
                <span
                  v-if="props.formattedRow.scheduled_status == 'Cancel'"
                  class="badge bg-secondary"
                >
                  {{ props.formattedRow.scheduled_status }}
                </span>
              </span>
            </template>
          </vue-good-table>
          <div class="text-center" v-if="isLoading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div
            class="modal fade"
            id="exampleModalSchedule"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Cancel Scheduled Feed
                  </h5>
                </div>
                <div class="modal-body">
                  <p>Are you sure, you want to Cancel?</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalSchedule"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    :class="isCancel ? 'disabled' : ''"
                    @click="cancelSchedule"
                  >
                    Yes
                    <span
                      v-if="isCancel"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Calendar Upcoming and Past Feed-->
     <Calendar
     :ScheduledFeed="ScheduledFeed"
     @getHistoryOfFeed="getHistoryOfFeed"   
     />

     
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import moment from "moment-timezone";
import Calendar from './calendar';
export default {
  name: "History",
  components: {
    Loading,
    Calendar
  },
  data() {
    return {
      historyData: [],
      scheduledData: [],
      pastData: [],
      isLoading: false,
      isCancel: false,
      ScheduledFeed:"Upcoming",
      id: "",
      columns: [
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Feeds",
          field: "post",
          html: true,
        },

        {
          label: "Media",
          field: "mediaUrls",
          sortable: false,
        },
        {
          label: "Schedule Date",
          field: "scheduled_date",
          // formatFn: this.formatFn,
        },
        {
          label: "Platform",
          field: "platforms",
          sortable: false,
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
          globalSearchDisabled: true,
        },

        // ...
      ],
      columns1: [
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Feeds",
          field: "post",
          html: true,
        },

        {
          label: "Media",
          field: "mediaUrls",
          sortable: false,
        },
        {
          label: "Schedule Date",
          field: "scheduled_date",
          // formatFn: this.formatFn,
        },
        {
          label: "Platform",
          field: "platforms",
          sortable: false,
        },
        {
          label: "Status",
          field: "scheduled_status",

          sortable: false,
        },

        // ...
      ],
    };
  },
  created() {
    this.getHistoryOfFeed();
  },
  methods: {
    getHistoryOfFeed() {
      this.isLoading = true;
      axios
        .get(process.env.VUE_APP_BASEURL + `/get-scheduled-feed`, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status) {
            //console.log(res.data)

            this.historyData = res?.data?.data;

            this.isLoading = false;
            // console.log(this.historyData)
          } else {
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    formatFn: function (value) {
      let date=new Date(value)
       var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return moment(newDate).format("YYYY-MM-DD HH:mm"); 
      // let val=new Date(value)(value).utc()
      // return moment(value).format("YYYY-MM-DD HH-mm");
    },
    formatFn1: function (value) {
      return value.toString();
    },
    openModal(row) {
      //console.log(row.feed_id)
      this.id = row.feed_id;
    },
    showLink(link, platform) {
      var html = "";
      if (platform == "twitter") {
        if (link.length > 1) {
          link.forEach(function (val, index) {
            html +=
              "<span>Thread " +
              (index + 1) +
              ': </span><a href="' +
              val +
              '" target="_blank" class="px-2 text-white">' +
              val +
              "</a><br>";
          });
        } else {
          html =
            '<a href="' +
            link +
            '" target="_blank" class="px-2 text-white">' +
            link +
            "</a>";
        }
      } else {
        html =
          '<a href="' +
          link +
          '" target="_blank" class="px-2 text-white">' +
          link +
          "</a>";
      }
      return html;
    },
    cancelSchedule() {
      this.isCancel = true;
      let obj = {
        feed_id: this.id,
      };
      // console.log(obj)
      axios
        .post(process.env.VUE_APP_BASEURL + `/delete-schedule-feed`, obj, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          this.isCancel = false;
          $("#exampleModalSchedule").modal("hide");
          this.$toasted.show(`Schedule Successfully Cancel`, {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000,
            singleton: true,
          });
          this.getHistoryOfFeed();
        })
        .catch(() => {
          this.isCancel = false;
          this.$toasted.show(`Something Went Wrong`, {
            theme: "bubble",
            type: "error",
            position: "top-center",
            duration: 1500,
            singleton: true,
          });
        });
    },
    setCalendar(ScheduledFeed) {
      this.ScheduledFeed = ScheduledFeed;
      
    },
    dateFormat:function(date){
      return date ? moment(date).format('MM-DD-YYYY hh:mm A') : '--'
    },
    modifyText:function(text){
      // Remove links to docs.ayrshare.com
      let textWithoutLinks = text.replace(/https:\/\/docs\.ayrshare\.com\/\S*/gi, '');
      
      // Replace "Contact us for assistance." with the modified message
      textWithoutLinks = textWithoutLinks.replace(/Contact us for assistance.\S*/gi, 'If you continue to experience issues, please contact us.');
      
      // Replace "Please see...for details." with a new message
      textWithoutLinks = textWithoutLinks.replace(/Please see.*?for details\./gis, 'Please contact us for more information.');
      
      return textWithoutLinks;
    }

  },
};
</script>

<style>
.vgt-table.bordered td,
.vgt-table.bordered th {
  border: 1px solid #dcdfe6;
  font-size: 14px !important;
  color: #000 !important;
}
.fb-img {
  height: 20px !important;
  width: 20px !important;
}
.schedule-post-history img {
  height: 40px;
}
/* .history th{
  flex-wrap: nowrap !important;
} */
</style>
