<template>
    <div class="hello m-5 reviews-admin-listing">
        <div class="d-flex justify-content-between">
            <div>
                <h4 class="m-1">Weekly Review Feeds (Monthly Script)</h4>
                <h5 class="m-1">Month: {{ getMonth( filter.monthType == 'CURRENT' ? 1 : 0 ) }}</h5>
            </div>
            
            <div class="d-flex justify-content-end">
                <div>
                    <a 
                        v-if="listing.length > 0 && filter.monthType == 'NEXT'"
                        @click="openEmailComposeModal"
                        v-tooltip="'Send an email notification to all clients about their weekly scheduled review feeds for the upcoming month.'"
                        class="text-white btn btn-sm m-2 flex-start-end btn-primary pointer-cursor"
                    >
                        Send Notification
                    </a>
                    <a 
                        v-if="filter.monthType == 'NEXT'"
                        :class="[reviewScheduleingStatus ? 'pointer-disabled btn-info' : 'btn-primary pointer-cursor']"
                        v-tooltip="reviewScheduleingStatus ? 'Scheduleing Already in Process' : 'Schedule Reviews'"
                        @click="scheduleReviews"
                        class="text-white btn btn-sm m-2 flex-start-end"
                        aria-current="page"
                    >
                        Run Schedule Reviews
                        <Loading v-if="reviewScheduleingStatus"/>
                    </a>
                </div>
            </div>

        </div>
        <div class="mt-4 mb-0">
            <a
                @click="() => {
                    filter.monthType = 'CURRENT'
                }"
                href="#"
                class="btn btn-primary btn-sm text-white"
                role="button"
                :class="[filter.monthType == 'CURRENT' ? 'active' : '']"
                >
                Current Month
            </a>
            
            <a
                href="#"
                @click="() => {
                    filter.monthType = 'NEXT'
                }"
                class="ms-2 btn btn-primary btn-sm text-white"
                role="button"
                :class="[filter.monthType == 'NEXT' ? 'active' : '']"
                >
                Next Month
            </a>
            
        </div>

        <template v-for="item in listing">
            <div class="row mt-2">
                <div class="col-md-12 mb-2">
                    <hr>
                    <h4>Client: {{item.name}}</h4>
                </div>
                <div class="col-md-12 mt-1">
                    <div class="row">
                        <div class="col-md-4 col-lg-3 mt-1 mb-1" v-for="entity in item.scheduled_reviews">
                            <div class="card">
                                <div class="card-body">
                                    <h6>Scheduled Date : {{ dateFormat(entity.scheduled_at) }}</h6>
                                    <span>Review: {{entity.feed.review.author}}</span>
                                    <br>
                                    <img
                                        :src="JSON.parse(entity.feed.media).url"
                                        class="img-fluid rounded-top"
                                        />
                                    <div class="d-flex justify-content-center">
                                        <span>Scheduled for platforms</span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <template v-for="platform in entity.platforms">
                                            <img
                                                :src="getPlatformImage(platform)"
                                                v-tooltip="platform ? $ucFirst(platform) : '--'"
                                                :alt="platform ? $ucFirst(platform) : '--'"
                                                style="width: 20px; margin-left: 5px"
                                            />
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <div style="margin: 15%;" class="row" v-if="listing.length == 0 && !isLoading">
            <div class="col-md-12 mt-4" style="display:flex; justify-content:center" v-if="filter.monthType == 'NEXT'">
                <span class="h6">
                    No scheduled feeds found for {{ getMonth() }}. Click this button 
                    <a  
                        :class="[reviewScheduleingStatus ? 'pointer-disabled btn-info' : 'btn-primary pointer-cursor']"
                        v-tooltip="reviewScheduleingStatus ? 'Scheduleing Already in Process' : 'Schedule Reviews'"
                        @click="scheduleReviews"
                        class="text-white btn btn-sm m-2 flex-start-end"
                        aria-current="page"
                        >
                            Run Schedule Reviews
                        <Loading v-if="reviewScheduleingStatus"/>
                    </a>
                    to generate the review feeds for the month.</span> 
            </div>
            <div class="col-md-12 mt-4" style="display:flex; justify-content:center" v-if="filter.monthType == 'CURRENT'">
                <span class="h6">
                    No scheduled feeds found for {{ getMonth(1) }}.
                </span> 
            </div>
        </div>

        <div class="modal fade" id="composeEmailModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalToggleLabel">
                            Compose an Email
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="to" class="form-label">To
                                <i class="bi bi-info-circle" v-tooltip="`Please select a user to send to a specific person, or choose 'All' to send to everyone.`"></i>
                            </label>
                            <v-select
                                label="name"
                                id="to"
                                placeholder="select users"
                                multiple
                                :class="[$v.composeEmail.users.$error ? 'is-invalid' : '']"
                                :options="usersDropDown"
                                v-model="composeEmail.users"
                            ></v-select>
                        </div>
                        <div class="mb-3">
                            <label for="" class="form-label">Message</label>
                            <br>
                            <small v-tooltip="'You can use these short codes in the message, and they will be replaced with the corresponding client data.'">Short Codes: </small>
                            <small v-tooltip="'The short code will be replaced by client name.'" class="text-danger">{client_name}, </small>
                            <small v-tooltip="'The short code will be replaced by client bussiess name.'" class="text-danger">{business_name}, </small>
                            <small v-tooltip="'The short code will be replaced by client scheduled review feeds.'" class="text-danger">{feeds}, </small>
                            <small v-tooltip="'The short code will be replaced by month for which the review feeds scheduled.'" class="text-danger">{month}</small>
                            <quill-editor
                            v-model="composeEmail.body"
                                ref="myQuillEditor"
                                :options="editorOption"
                                :class="[$v.composeEmail.body.$error ? 'is-invalid' : '']"
                                />
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button @click="sendEmail" class="btn btn-primary" :disabled="sendEmailLoader">
                            Send
                            <Loading v-if="sendEmailLoader"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import moment from "moment-timezone";
import Loading from '@/components/user/reviews/loading.vue'

import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import yelpImage from "../../../assets/yelp.png"
import googleImage from "../../../assets/google.png"
import facebookImage from "../../../assets/facebook.png"
import twitterImage from "../../../assets/twitter.png"
import linkedinImage from "../../../assets/linkedin.png"
import instagramImage from "../../../assets/instagram.png"
import pinterestImage from "../../../assets/pinterest.png"
import telegramImage from "../../../assets/telegram.png"
import googleBusinessImage from "../../../assets/google_business.png"
import facebookGroupsImage from "../../../assets/facebook_groups.png"

export default {
    name: 'monthlyReviews',
    components: {
        vSelect,
        Loading,
		quillEditor
    },
    watch:{
        'filter.monthType':{
            handler:function(){
                this.fetchListing()
            },
            deep: true
        }
    },
    data() {
        return {
            filter:{
                monthType:'NEXT'
            },
            reviewScheduleingStatus: false,
            listing: [],
            usersDropDown:[],
            editorOption: {
				placeholder: "Compose email",
				readOnly: true,
				theme: "snow",
				modules: {
                    toolbar: [
						['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                            { 'header': 1 }, 
                            { 'header': 2 }
                        ],
                        [
                            { 'list': 'ordered' },
                            { 'list': 'bullet' }
                        ],
                        [
                            { 'indent': '-1' },
                            { 'indent': '+1' },
                            { 'align': [] }
                        ],
                        [
                            { 'size': ['small', false, 'large', 'huge'] },
                            { 'font': [] },
                            { 'header': [1, 2, 3, 4, 5, 6, false] }
                        ],
                        [
                            { 'color': [] },
                            { 'background': [] },
                            'clean'
                        ],
					],
                }
			},
            composeEmail:{
                users:[],
                body:`
                <p>Hi {client_name},</p>
                <p><br></p>
                <p>The Weekly Reviews Feeds have been scheduled for {month}.</p>
                <p>Please review the details below.</p>
                <p><br></p>
                <p><strong>Scheduled Feeds:</strong></p>
                <p>{feeds}</p>
                <p><br></p>
                <p>Thank you,</p>
                <p>Best regards</p>`
            },
            sendEmailLoader:false
        }
    },
    validations: {
        composeEmail: {
            users: {
                required,
            },
            body: {
                required,
            }
        },
    },
    computed: {},
    created: async function () {
        this.isLoading = true

        await this.fetchUsersDropDown({is_for_compose_email:1}).then((resp) => {
            if(resp.status) 
            {
                this.usersDropDown = [
                    resp.data.length > 1 ?
                    {
                        id:"all",
                        name:'To All Users',
                    }:'',
                    ...resp.data
                ]
            }
            else
            {
                console.log(resp.message)
            }
        })

        this.fetchListing()
    },
	mounted:function(){
        let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()

    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        next()
    },
    methods: {
        ...mapActions([
            'fetchUsersDropDown',
            'getSetting',
        ]),
        fetchListing: function (page = 1, limit = '', field = '', direction = '', search = '', user_id = '', platform = '', status = ''){
            this.isLoading = true
            
            let url = `?month_type=${this.filter.monthType}`
            this.$helper.getRequest(`monthly-reviews${url}`).then((resp) => {
                let {data}      = resp
                this.listing    = data
                this.isLoading  = false
                this.reviewScheduleingStatus = parseInt(resp.review_scheduling_status)

            })
            .catch((e) => {
                console.log(' Something went wrong please try later ', e)
            })
        },
        notify: function (msg, type = 'success') {
            this.$toasted.show(msg, {
                theme: 'bubble',
                type: type,
                position: 'top-center',
                duration: 5000,
                singleton: true,
            })
        },
        scheduleReviews:function(){
            if(!this.reviewScheduleingStatus)
            {
                let month = moment().add(1,'M').format('MMMM');
                if(confirm(`Are you sure you want to schedule the review for posting in ${month}?`))
                {
                    this.reviewScheduleingStatus = 1
                    this.notify("Process Started");
                    this.$helper.getRequest('monthly-reviews/schedule').then((resp) => {
                        this.notify(resp.message);
                        this.reviewScheduleingStatus = 0
                        this.fetchListing()
                    })
                }
            }   
        },
        dateFormat:function(date){
            return date ? moment(date).format('MM-DD-YYYY hh:mm A (ddd)') : '--'
        },
        getPlatformImage:function (platform){
            let images = {
                'yelp':yelpImage,
                'google':googleImage,
                'facebook':facebookImage,
                'instagram':instagramImage,
                'twitter':twitterImage,
                'linkedin':linkedinImage,
                'pinterest':pinterestImage,
                'telegram':telegramImage,
                'gmb':googleBusinessImage,
                'fbg':facebookGroupsImage
            }
            return platform ? images[platform] : '';
        },
        openEmailComposeModal:function(){
            $('body').find("#composeEmailModal").modal('show')
        },
        closeEmailComposeModal:function(){
            $('body').find("#composeEmailModal").modal('hide')
        },
        sendEmail:function(e){
            e.preventDefault();
            this.sendEmailLoader = true
            this.$v.composeEmail.$touch()    
            if(this.composeEmail.users.length > 0)
            {
                if(this.composeEmail.body)
                {
                    if(confirm('Are you sure you want to send email ?'))
                    {
                        this.$helper.postRequest('monthly-reviews/send-email', this.composeEmail).then((resp) => {
                            
                            this.notify(resp.message,resp.status ? 'success' : 'danger')
                            this.sendEmailLoader = false;
                            let temp = this
                            setTimeout(function(){
                                temp.closeEmailComposeModal()
                            },1000)
                        })
                    }
                }
                else
                {
                    this.sendEmailLoader = false;
                    this.notify("Please enter message also",'danger')
                }
            }
            else
            {
                this.sendEmailLoader = false;
                this.notify("Please Select Users first",'danger')
            }
        },
        getMonth:function(current = 0){
            if(current)
            {
                return moment().format('MMMM (YYYY)')
            }
            else
            {
                return moment().add(1,'M').format('MMMM (YYYY)')
            }
        },
    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.openModalBusiness {
    cursor: pointer;
}

.rounded-circle {
    content: '';
    position: absolute;
    top: -1px;
    right: -3px;
    width: 8px;
    height: 8px;
}
.star {
    color: gold;
    font-size: 16px;
}

.wrapper {
    position: relative;
    width: 100%;
}

.highlighted-text {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    height: 150px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.pointer-cursor {
    cursor: pointer;
}

.pointer-disabled {
    cursor: not-allowed;
}

.btn.btn-primary.dropdown-toggle.position-relative.show::after {
    border-top: 0 !important;
    border-bottom: 0.3em solid !important;
}
</style>

<style>
.reviews-admin-listing table#vgt-table tr {
    border-bottom-width: 4px !important;
}
</style>