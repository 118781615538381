<template>
  <div class="border bg-white rounded filter_container p-3 mt-4">
    <div class="row filters justify-content-between">
      <div class="d-flex align-items-center col-lg-2">
        <p class="align-items-center fw-bold mb-0">Filter by:</p>
        <label class="me-3 text-capitalize ms-3">type:</label>
      </div>
      <div
        class="d-flex align-items-center justify-content-xl-between flex-wrap col-lg-10"
      >
      
        <button
          v-for="item in typeOptions"
          :key="item.name"
          :class="{ gris:types == item.name || types==item.value}"
          type="button"
          class="btn btn-outline-secondary text-capitalize px-4 me-lg-3 my-2 mob-100 filter-buttons shadow-none"
          @click="setSelected(item)"
        >
          <span>{{ item.name }}</span>
        </button>
      </div>
    </div>
    <div
      class="row mt-2 filters justify-content-between"
      v-if="type.value == 'events'"
    >
      <div class="col-lg-2 d-flex align-items-center">
        <p class="mt-2 text-capitalize fw-bold mob-100">date between</p>
      </div>
      <div
        class="col-lg-10 d-flex align-items-center flex-wrap justify-content-lg-between"
      >
        <v-select
          class="my-2 mob-100"
          style="width: 160px"
          v-model="month"
          placeholder="starting month"
          label="name"
          item-value="value"
          :clearable="false"
          @input="setSelectedStartMonth"
          :options="monthOptions"
        ></v-select>
        <v-select
          class="my-2 ms-lg-3 ms-md-0 ms-sm-0 ms-xs-0 mob-100"
          style="width: 160px"
          v-model="day"
          placeholder="starting day"
          label="name"
          item-value="value"
          :clearable="false"
          :options="dayOptions2"
        ></v-select>
        <div class="d-flex align-items-center my-2 mob-100">
          <span class="text-capitalize mx-3">and</span>
          <v-select
            class="me-lg-3 me-md-0 me-sm-0 me-xs-0 mob-100"
            style="width: 160px"
            v-model="end_month"
            placeholder="end month"
            label="name"
            item-value="value"
            @input="setSelectedMonth"
            :clearable="false"
            :options="monthOptions"
          ></v-select>
        </div>
        <v-select
          class="me-lg-3 me-md-0 me-sm-0 me-xs-0 mob-100 my-2 filter-buttons"
          style="width: 160px"
          v-model="end_day"
          placeholder="ending day"
          label="name"
          item-value="value"
          :clearable="false"
          :options="dayOptions"
        ></v-select>
      </div>
    </div>
    <div class="row filters justify-content-between">
      <div class="d-flex align-items-center my-3 col-lg-2 mob-100">
        <p class="fw-bold me-4 mb-0">Search for</p>
      </div>
      <div
        class="d-flex align-items-center justify-content-between mt-3 flex-wrap mob-100 col-lg-10"
      >
        <input
          class="form-control search-for mb-3 shadow-none"
          v-model="search"
          type="search"
          v-on:keyup.enter="onEnter"
          placeholder="Search"
          aria-label="Search"
        />
        <div class="d-flex align-items-center">
          <label class="mx-1 fw-bold me-3 mb-3">Attached media</label>
          <v-select
            class="custom-select mb-3"
            v-model="mediaType"
            placeholder="any"
            :clearable="false"
            :options="mediaOptions"
          ></v-select>
        </div>
        <div class="d-flex align-items-center">
          <label class="fw-bold me-3 mb-3"> Already used</label>
          <v-select
            class="custom-select mb-3"
            v-model="used"
            placeholder="any"
            item-value="value"
            item-text="name"
            label="name"
            :clearable="false"
            @input="setSelectedUsed"
            :options="usedOption"
          ></v-select>
        </div>
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-sm px-4 py-2 mb-3 btn-secondary me-3 filter-buttons shadow-none"
            @click="resetFilter"
          >
            Reset
          </button>
          <button
            type="button"
            class="btn btn-sm px-4 mb-3 btn-cus-primary me-3 filter-buttons shadow-none"
            :class="loading ? 'disabled' : ''"
            @click="applyFilter"
          >
            Apply
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="third d-flex align-items-center w-100 justify-content-between"
    ></div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "CustomFilter",
  components: {
    vSelect,
  },
  data() {
    return {
      loading: false,
      types: localStorage.getItem('type') ? localStorage.getItem('type') : 'any',
      obsType:localStorage.getItem('type') ? true : false,
      color: false,
      type: "",
      month: {
        name: new Date().toLocaleString("default", { month: "long" }),
        value: new Date().getMonth() + 1,
      },
      day: "",
      end_month: { name: "December", value: 12 },
      end_day: { name: "31st", value: 31 },
      dayFebOptions: [
        {
          name: "1st",
          value: 1,
        },
        {
          name: "2nd",
          value: 2,
        },
        {
          name: "3rd",
          value: 3,
        },
        { name: "4th", value: 4 },
        {
          name: "5th",
          value: 5,
        },
        { name: "6th", value: 6 },
        { name: "7th", value: 7 },
        { name: "8th", value: 8 },
        { name: "9th", value: 9 },
        { name: "10th", value: 10 },
        { name: "11th", value: 11 },
        { name: "12th", value: 12 },
        { name: "13th", value: 13 },
        { name: "14th", value: 14 },
        { name: "15th", value: 15 },
        { name: "16th", value: 16 },
        { name: "17th", value: 17 },
        { name: "18th", value: 18 },
        { name: "19th", value: 19 },
        { name: "20th", value: 20 },
        { name: "21st", value: 21 },
        { name: "22nd", value: 22 },
        { name: "23rd", value: 23 },
        { name: "24th", value: 24 },
        { name: "25th", value: 25 },
        { name: "26th", value: 26 },
        { name: "27th", value: 27 },
        { name: "28th", value: 28 },
        { name: "29th", value: 29 },
      ],
      dayOptions1: [
        {
          name: "1st",
          value: 1,
        },
        {
          name: "2nd",
          value: 2,
        },
        {
          name: "3rd",
          value: 3,
        },
        { name: "4th", value: 4 },
        {
          name: "5th",
          value: 5,
        },
        { name: "6th", value: 6 },
        { name: "7th", value: 7 },
        { name: "8th", value: 8 },
        { name: "9th", value: 9 },
        { name: "10th", value: 10 },
        { name: "11th", value: 11 },
        { name: "12th", value: 12 },
        { name: "13th", value: 13 },
        { name: "14th", value: 14 },
        { name: "15th", value: 15 },
        { name: "16th", value: 16 },
        { name: "17th", value: 17 },
        { name: "18th", value: 18 },
        { name: "19th", value: 19 },
        { name: "20th", value: 20 },
        { name: "21st", value: 21 },
        { name: "22nd", value: 22 },
        { name: "23rd", value: 23 },
        { name: "24th", value: 24 },
        { name: "25th", value: 25 },
        { name: "26th", value: 26 },
        { name: "27th", value: 27 },
        { name: "28th", value: 28 },
        { name: "29th", value: 29 },
        { name: "30th", value: 30 },
      ],
      dayOptions2: [
        {
          name: "1st",
          value: 1,
        },
        {
          name: "2nd",
          value: 2,
        },
        {
          name: "3rd",
          value: 3,
        },
        { name: "4th", value: 4 },
        {
          name: "5th",
          value: 5,
        },
        { name: "6th", value: 6 },
        { name: "7th", value: 7 },
        { name: "8th", value: 8 },
        { name: "9th", value: 9 },
        { name: "10th", value: 10 },
        { name: "11th", value: 11 },
        { name: "12th", value: 12 },
        { name: "13th", value: 13 },
        { name: "14th", value: 14 },
        { name: "15th", value: 15 },
        { name: "16th", value: 16 },
        { name: "17th", value: 17 },
        { name: "18th", value: 18 },
        { name: "19th", value: 19 },
        { name: "20th", value: 20 },
        { name: "21st", value: 21 },
        { name: "22nd", value: 22 },
        { name: "23rd", value: 23 },
        { name: "24th", value: 24 },
        { name: "25th", value: 25 },
        { name: "26th", value: 26 },
        { name: "27th", value: 27 },
        { name: "28th", value: 28 },
        { name: "29th", value: 29 },
        { name: "30th", value: 30 },
        { name: "31st", value: 31 },
      ],
      dayOptions3: [
        {
          name: "1st",
          value: 1,
        },
        {
          name: "2nd",
          value: 2,
        },
        {
          name: "3rd",
          value: 3,
        },
        { name: "4th", value: 4 },
        {
          name: "5th",
          value: 5,
        },
        { name: "6th", value: 6 },
        { name: "7th", value: 7 },
        { name: "8th", value: 8 },
        { name: "9th", value: 9 },
        { name: "10th", value: 10 },
        { name: "11th", value: 11 },
        { name: "12th", value: 12 },
        { name: "13th", value: 13 },
        { name: "14th", value: 14 },
        { name: "15th", value: 15 },
        { name: "16th", value: 16 },
        { name: "17th", value: 17 },
        { name: "18th", value: 18 },
        { name: "19th", value: 19 },
        { name: "20th", value: 20 },
        { name: "21st", value: 21 },
        { name: "22nd", value: 22 },
        { name: "23rd", value: 23 },
        { name: "24th", value: 24 },
        { name: "25th", value: 25 },
        { name: "26th", value: 26 },
        { name: "27th", value: 27 },
        { name: "28th", value: 28 },
        { name: "29th", value: 29 },
        { name: "30th", value: 30 },
        { name: "31st", value: 31 },
      ],
      dayOptions: [
        {
          name: "1st",
          value: 1,
        },
        {
          name: "2nd",
          value: 2,
        },
        {
          name: "3rd",
          value: 3,
        },
        { name: "4th", value: 4 },
        {
          name: "5th",
          value: 5,
        },
        { name: "6th", value: 6 },
        { name: "7th", value: 7 },
        { name: "8th", value: 8 },
        { name: "9th", value: 9 },
        { name: "10th", value: 10 },
        { name: "11th", value: 11 },
        { name: "12th", value: 12 },
        { name: "13th", value: 13 },
        { name: "14th", value: 14 },
        { name: "15th", value: 15 },
        { name: "16th", value: 16 },
        { name: "17th", value: 17 },
        { name: "18th", value: 18 },
        { name: "19th", value: 19 },
        { name: "20th", value: 20 },
        { name: "21st", value: 21 },
        { name: "22nd", value: 22 },
        { name: "23rd", value: 23 },
        { name: "24th", value: 24 },
        { name: "25th", value: 25 },
        { name: "26th", value: 26 },
        { name: "27th", value: 27 },
        { name: "28th", value: 28 },
        { name: "29th", value: 29 },
        { name: "30th", value: 30 },
        { name: "31st", value: 31 },
      ],
      monthOptions: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "Septempber",
          value: 9,
        },
        { name: "October", value: 10 },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      typeOptions: [
        {
          name: "any",
          value: "any",
        },
        {
          name: "YouTube",
          value: "external",
        },
        {
          name: "education",
          value: "education",
        },
        {
          name: "inspirational",
          value: "inspirational",
        },
        {
          name: "funny",
          value: "funny",
        },
        {
          name: "observances",
          value: "events",
        },
        {
          name: "Benefits",
          value: "benefits",
        },
        {
          name: "Reviews",
          value: "review",
        },
        {
          name: "Custom",
          value: "custom",
        },
      ],
      mediaType: "",
      perPage: 10,
      order: {
        name: "high to low",
        value: "DESC",
      },
      search: "",
      used: "",
      orderby: {
        name: "Recently added",
        value: "date",
      },
      orderOptions: [
        {
          name: "low to high",
          value: "ASC",
        },
        {
          name: "high to low",
          value: "DESC",
        },
      ],
      orderByOptions: [
        {
          name: "Recently added",
          value: "date",
        },
        {
          name: "observance date (when applicable)",
          value: "observance_date",
        },
        {
          name: "popularity",
          value: "used",
        },
      ],
      mediaOptions: ["image", "video"],
      usedOption: [
        {
          name: "Yes",
          value: 1,
        },
        {
          name: "No",
          value: 0,
        },
      ],
      options: ["ab", "ba", "ac", "ca"],
    };
  },
  created() {
    let n = new Date().getDate();
    let m = new Date().getMonth() + 1;
    //console.log(n-1,new Date().getMonth())
    if (m == 4 || m == 6 || m == 9 || m == 11) {
      // this.st_day={name:"30th",value:30}
      this.dayOptions2 = this.dayOptions1;
    } else if (m == 2) {
      //  this.end_day={name:"29th",value:29}
      this.dayOptions2 = this.dayOptions1;
    } else {
      //this.end_day={name:"31st",value:31}
    }
    if (n == 1) {
      this.day = {
        name: this.dayOptions[0]?.name,
        value: n,
      };
    } else {
      this.day = {
        name: this.dayOptions[n - 1]?.name,
        value: n,
      };
    }
   
  },
  beforeMount(){
    var selected_event = localStorage.getItem('type') ? localStorage.getItem('type') : '';
    if(selected_event == 'events'){
      this.type = {value : selected_event};
    }
  },
  methods: {
    selectedOrderBy(event) {
      // console.log(event);
    },
    setSelectedMonth(event) {
      if (
        event.value == 4 ||
        event.value == 6 ||
        event.value == 9 ||
        event.value == 11
      ) {
        this.end_day = { name: "30th", value: 30 };
        this.dayOptions = this.dayOptions1;
      } else if (event.value == 2) {
        this.end_day = { name: "29th", value: 29 };
        this.dayOptions = this.dayFebOptions;
      } else {
        this.end_day = { name: "31st", value: 31 };
      }
    },
    setSelectedStartMonth(event) {
      if (
        event.value == 4 ||
        event.value == 6 ||
        event.value == 9 ||
        event.value == 11
      ) {
        //this.end_day={name:"30th",value:30}
        this.dayOptions2 = this.dayOptions1;
      } else if (event.value == 2) {
        // this.end_day={name:"29th",value:29}
        this.dayOptions2 = this.dayFebOptions;
      } else {
        this.dayOptions2 = this.dayOptions3;
        // this.end_day={name:"31st",value:31}
      }
    },
    setSelected(event) {
      this.types = event.name;
      localStorage.setItem('type',event.value);
     
      this.type = event;
      if (event.name == "observances") {
        this.orderby = {
          name: "any",
          value: "",
        };
        this.order = {
          name: "low to high",
          value: "ASC",
        };
        let obj = {
          orderby: "",
          order: "ASC",
        };
        //this.$emit('typeChange',obj)
      } else {
        (this.orderby = {
          name: "Recently added",
          value: "date",
        }),
          (this.order = {
            name: "high to low",
            value: "DESC",
          });
        let obj = {
          orderby: "date",
          order: "DESC",
        };
        // this.$emit('typeChange',obj)
      }
      this.loading = false;
      let obj;
      if (this.type?.value == "events") {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: this.day ? this.day.value : "",
          observance_end_day: this.end_day ? this.end_day.value : "",
          observance_start_month: this.month ? this.month.value : "",
          observance_end_month: this.end_month ? this.end_month.value : "",
        };
        this.$emit("selectedEvent", 'events');
      } else {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: "",
          observance_end_day: "",
          observance_start_month: "",
          observance_end_month: "",
        };
        this.$emit("selectedEvent", 'other');
      }

      this.$emit("onselect", obj);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    setSelectedUsed(event) {
      // this.used=event.value
    },
    setSelectedOrder(event) {
      // this.order=event.value;
    },
    onEnter() {
      let obj;
      if (this.type?.value == "events") {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: this.day ? this.day.value : "",
          observance_end_day: this.end_day ? this.end_day.value : "",
          observance_start_month: this.month ? this.month.value : "",
          observance_end_month: this.end_month ? this.end_month.value : "",
        };
        this.$emit("selectedEvent", 'events');
      } else {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: "",
          observance_end_day: "",
          observance_start_month: "",
          observance_end_month: "",
        };
        this.$emit("selectedEvent", 'other');
      }

      // console.log("obj", obj);
      this.$emit("onselect", obj);
    },
    applyFilter() {
      this.loading = true;
      let obj;
      
      if (this.type?.value == "events") {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: this.day ? this.day.value : "",
          observance_end_day: this.end_day ? this.end_day.value : "",
          observance_start_month: this.month ? this.month.value : "",
          observance_end_month: this.end_month ? this.end_month.value : "",
        };
        this.$emit("selectedEvent", 'events');
      } else {
        obj = {
          type: this.type?.value ? this.type?.value : "",
          order_by: this.orderby?.value ? this.orderby?.value : "",
          ordertype: this.order?.value ? this.order?.value : "",
          media_type: this.mediaType,
          used: this.used ? this.used?.value : "",
          search: this.search,
          page: 1,
          perPage: this.perPage,
          observance_start_day: "",
          observance_end_day: "",
          observance_start_month: "",
          observance_end_month: "",
        };
        this.$emit("selectedEvent", 'other');
      }

      this.$emit("onselect", obj);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    resetFilter() {
      this.types = "any";
      this.mediaType = "";
      this.type = "";
      this.perPage = 10;
      (this.order = {
        name: "high to low",
        value: "DESC",
      }),
        (this.search = "");
      this.used = "";
      this.orderby = {
        name: "Recently added",
        value: "date",
      };
      let obj = {
        type: this.type?.value ? this.type?.value : "",
        order_by: "date",
        ordertype: "DESC",
        media_type: this.mediaType,
        used: this.used ? this.used?.value : "",
        search: this.search,
        page: 1,
        perPage: this.perPage,
      };
      let obj1 = {
        orderby: "date",
        order: "DESC",
      };
      this.$emit("typeChange", obj1);
      this.$emit("selectedEvent", 'other');
      this.$emit("onselect", obj);
    },
  },
};
</script>

<style>
.gris {
  border: 2px solid #05cffdf2 !important;
}
.filter_container {
  padding: 10px;
}
.btn.custom-disabled {
  color: #8d8d8d;
  background-color: transparent;
  border-color: #e3e3e3;
  pointer-events: none;
}
.filter-coming-soon {
  font-size: 10px;
  left: 0;
  bottom: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
