import axios from 'axios'
const state = {
  users: [],
  user: {}
};
const getters = {
  usersList: state => state.users,
  userList: state => state.user
};
const actions = {
  async fetchUsers({ commit }) {
    return await axios.get(`${process.env.VUE_APP_BASEURL}/getAllUsersList`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {
      commit("setUsers", response.data?.users_data?.data)
      return response.data
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }

    });
    // console.log(response.data)

  },
  async fetchUser({ commit }, id) {
    await axios.get(`${process.env.VUE_APP_BASEURL}/getupdateProfile`, {

      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }

    }).then((response) => {
      commit("setUser", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }

    });


  },
  async fetchUserById({ commit }, id) {
    await axios.get(`${process.env.VUE_APP_BASEURL}/dashboard/getupdateProfile?user_id=${id}`, {

      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }

    }).then((response) => {
      commit("setUser", response.data)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }

    });;


  },
  async updateUser({ commit }, user) {
    await axios.post(`${process.env.VUE_APP_BASEURL}/updateProfile`, user, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }

    }).then((response) => {
      commit("setUser", response.data)

    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });

  },
  async updateUser1({ commit }, user) {
    await axios.post(`${process.env.VUE_APP_BASEURL}/dashboard/updateProfile`, user, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }

    }).then((response) => {
      commit("setUser1", response.data?.user_data)

    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });;

  },
  async updateAccount({ commit }, user) {
    const response = await axios.post(`${process.env.VUE_APP_BASEURL}/activateDeactivateAccount`, user, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }

    }).then(() => {
      commit("setUpdateUsers", user)
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
    });

  },
  async addUsers({ commit }, user) {
    const response = await axios.post("http://localhost:3000/users", user, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    });
    commit("addNewUser", response.data)
  },
  async deleteUsers({ commit }, id) {
    let obj = {
      id: id
    }
    await axios.post(`${process.env.VUE_APP_BASEURL}/deleteAccount`, obj, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then(() => {
      // console.log('remo',id)
      commit("removeUser", id)
      this.$toasted.show(res?.data?.message, {
        theme: "bubble",
        type: "success",
        position: "top-center",
        duration: 3000,
        singleton: true,
      });
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }
      this.$toasted.show(`Something went wrong`, {
        theme: "bubble",
        type: "error",
        position: "top-center",
        duration: 2000,
        singleton: true,
      });
    });

  },
  async fetchUsersDropDown({ commit }, data) {
    let is_for_compose_email = data ? data.is_for_compose_email : 0
    return await axios.get(`${process.env.VUE_APP_BASEURL}/users/drop-down?${is_for_compose_email ? 'is_for_compose_email=1' : ''}`, {
      headers: {
        'Content-type': 'application/json',
        'token': `${localStorage.getItem('token')}`
      }
    }).then((response) => {
      return response.data
    }).catch((err) => {
      if (err.message == 'Request failed with status code 401') {
        localStorage.removeItem('token');
        this.$router.push("/login");
      }

    });
    // console.log(response.data)

  },
};
const mutations = {
  setUsers: (state, users) => (
    state.users = users
  ),
  setUser: (state, user) => (
    state.user = user
  ),
  setUser1: (state, user) => {
    const index = state.users.findIndex(todo => todo.id === user.id);
    // console.log('at',state.users)
    if (index !== -1) {
      state.users.splice(index, 1, user);
      //console.log('at',state.users)
    }
  },
  addNewUser: (state, user) => state.users.unshift(user),
  removeUser: (state, id) => (

    state.users = state.users.filter(user => user.id !== id)
    // state.users.splice(user => user.id, 1)
  ),
  setUpdateUsers: (state, user) => {
    let ind = state.users.findIndex(u => u.id === user.id)
    state.users[ind].account_status = !state.users[ind].account_status
    //console.log(ind,state)
  }
};
export default {
  state,
  getters,
  actions,
  mutations
}