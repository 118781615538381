<template>
  <div class="container">
    <custom-filter
      @onselect="SelectFilter"
      @typeChange="typeSelect"
      @selectedEvent="changeOrderByValues"
    ></custom-filter>

    <div class="row align-items-center flex-wrap mt-4 py-3 control-div px-2">
      <div class="p-1 w-auto">
        <div class="d-flex">
          <a
            class="btn btn-cus-primary px-2 shadow-none"
            data-bs-toggle="modal"
            @click="openModal"
            data-bs-target="#videoModal"
            target="_blank"
          >
            <small class="text-nowrap">
              <i class="bi bi-play-circle me-1"></i>See how this works</small
            >
          </a>
          <div class="mx-1">
            <router-link
              style="text-decoration: none"
              class="text-white"
              aria-current="page"
              to="/add/post"
            >
              <button class="btn btn-cus-primary px-2 shadow-none">
                <small class="text-nowrap"
                  ><i class="bi bi-plus-circle me-1"></i> Add Post</small
                >
              </button></router-link
            >
          </div>
        </div>
      </div>
      <div class="p-1 w-auto">
        <div class="d-flex align-items-center flex-wrap">
          <div class="">
            <label class="text-nowrap text-secondary me-1">
              Order results by</label
            >
          </div>
          <div class="d-flex">
            <select
              class="form-select text-secondary order-by-filter shadow-none"
              @change="selectedOrderBy"
              v-model="orderby"
              aria-label="Default select example"
            >
              <option value="">Any</option>
              <option value="date">Recently added</option>
              <option value="observance_date" v-if="showObservanceDate">Observance Date</option>
              <option value="used">Popularity</option>
            </select>
            <select
              class="form-select text-secondary ms-2 shadow-none"
              v-model="order"
              @change="onSelect"
              aria-label="Default select example"
            >
              <!-- <option value="">Any</option> -->
              <option value="ASC">low to high</option>
              <option value="DESC">high to low</option>
            </select>
          </div>
        </div>
      </div>
      <div class="p-1 w-auto">
        <div class="d-flex align-items-center flex-wrap">
          <p class="text-nowrap pages_count text-secondary mb-0" v-if="total">
            Feeds
            <span class="fw-bold">{{ feedsList.feeds_data.from }}</span> to
            <span class="fw-bold">{{ feedsList.feeds_data.to }}</span> out of
            <span class="fw-bold">{{ total }} </span>
          </p>
          <paginate
            :class="paginateDisable ? 'custom-pagination-disabled' : ''"
            v-model.lazy="page"
            :page-count="lastPage"
            :page-range="3"
            :click-handler="paginationCallback"
            :disabled-class="'pagination-disabled'"
            :container-class="'VuePagination__pagination pagination VuePagination__pagination m-0 px-2'"
            :page-class="'VuePagination__pagination-item page-item'"
            :page-link-class="'page-link shadow-none fw-bold'"
            :prev-link-class="'page-link shadow-none'"
            :next-link-class="'page-link shadow-none'"
            :prev-text="`<i class='bi bi-chevron-left icon-bold'></i>`"
            :next-text="`<i class='bi bi-chevron-right icon-bold'></i>`"
          >
          </paginate>
        </div>
      </div>
      <div class="p-1 w-auto">
        <div class="d-flex">
          <select
            class="form-select text-secondary ps-2 pe-0 shadow-none"
            style="width: 60px; border: 1px solid #dee2e6; border-radius: 0px"
            v-model="perPage"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <label
            class="text-nowrap d-flex align-items-center text-secondary ms-1"
            >Per Page</label
          >
        </div>
      </div>
    </div>
    <div :key="refresh">
      <div
        style="position: relative"
        class="row cards-row"
        v-for="(feeds, index) of displayedRecords"
        :key="index"
      >
        <Card
          :total_copy_rem_tmp="total_copy_rem_tmp"
          :feeds="feeds"
          :feedType="feedsType"
          :ind="index"
          :keyId="refresh"
          :upId="uploadId"
          :cancelFeed="isCancel"
          :uploadLogoShowToggle="uploadLogoShow"
          :isImageToggle="isImage"
          @onuploadLogo="uploadLogo"
          @onselect="SelectFilter"
          @changeId="setId"
          @updatedLimit="checkUserData"
          @showImgInPopup="showImgInPopup"
          @deleteFeed="deleteFeed"
          @cancelFeed="cancelFeed"
          @Scheduling="SchedulingFeed"
          @openUploadLogoFeed="uploadFeedLogo"
          @LogoError="LogoErrorToggle"
        />
        <loading
          :active.sync="loading"
          color="#157DEC"
          :is-full-page="false"
        ></loading>
      </div>
    </div>
    <div id=""></div>
    <div v-if="feeds.length < 1 && !loading" class="text-align-center">
      <h4 class="no_data">No Data Found</h4>
    </div>

    <div class="d-flex justify-content-end mb-5 flex-wrap">
      <p class="m-2" v-if="total">
        Feeds
        <span class="fw-bold">{{ feedsList.feeds_data.from }}</span> to
        <span class="fw-bold">{{ feedsList.feeds_data.to }}</span> out of
        <span class="fw-bold">{{ total }} </span>
      </p>
      <paginate
        :class="paginateDisable ? 'custom-pagination-disabled' : ''"
        v-model.lazy="page"
        :page-count="lastPage"
        :page-range="3"
        :click-handler="paginationCallback"
        :container-class="'VuePagination__pagination pagination VuePagination__pagination m-0 px-2'"
        :page-class="'VuePagination__pagination-item page-item'"
        :page-link-class="'page-link shadow-none fw-bold'"
        :prev-link-class="'page-link shadow-none'"
        :next-link-class="'page-link shadow-none'"
        :disabled-class="'pagination-disabled'"
        :prev-text="`<i class='bi bi-chevron-left icon-bold'></i>`"
        :next-text="`<i class='bi bi-chevron-right icon-bold'></i>`"
      >
      </paginate>
    </div>
    <!-- Button trigger modal -->
    <!-- style="resize:both;overflow:auto" -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="videoModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header video-tutorial-header">
            <h5 class="modal-title" id="exampleModalLabel">
              PatientSites Social Feeds Tutorial
            </h5>
            <button
              type="button"
              class="btn-close bg-white"
              aria-label="Close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div
              style="
                position: relative;
                padding-bottom: 0%;
                height: 443px;
              "
            >
              <iframe
                src="https://play.vidyard.com/Jhf17A15KgKfDa2wHeMqiT?disable_popouts=1&v=4.3.6&type=inline"
                frameborder="0"
                webkitallowfullscreenuploadFeedsLogo
                mozallowfullscreen
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
            <div class="mt-2 text-center">
              <button
                type="button"
                class="btn btn-outline-dark"
                data-bs-dismiss="modal"
              >
                <i class="bi bi-x"></i> Close
              </button>
            </div>
          </div>
          <!-- <div class="modal-footer d-flex justify-content-center">
          
             <button type="button" class="btn btn-primary">Save changes</button> 
          </div> -->
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="showImgInPopup"
      tabindex="-1"
      aria-labelledby="showImgInPopupLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content bg-transparent border-0">
          <div
            class="modal-body px-0"
            style="width: fit-content; margin: 0 auto; z-index: 0"
          >
            <div class="popup-img-div">
              <div class="popup-d w-fit-content m-auto position-relative">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  class="close btn btn-close custom-btn bg-white p-3"
                ></button>
                <img class="h-auto img-fluid" :src="popupImgSrc" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="'deleteFeedModal'"
      tabindex="-1"
      aria-labelledby="deleteFeedModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteFeedModalLabel">Delete Feed</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {{ deleteFeedMsg ? deleteFeedMsg : 'Are you sure, you want to delete this feed ?' }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="DeleteConfirm"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="'cancelSchedule'"
      tabindex="-1"
      aria-labelledby="cancelScheduleLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cancelScheduleLabel">
              Cancel Scheduled Feed
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are you sure, you want to cancel ?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="cancelSchedule"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      tabindex="-1"
      :id="`Image-upload`"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <input type="hidden" id="feed-id-popup" value="" />
          <div class="modal-header py-3 w-100 flex-wrap">
            <h3 class="modal-title w-100 text-center">Select your logo</h3>
            <span
              v-if="LogoError"
              class="badge bg-success mx-auto my-2"
              >Click the circle below to upload your logo</span
            >
          </div>
          <div class="progress" v-if="selectedFile">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              v-bind:style="{ width: length }"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div class="modal-body">
            <form>
              <div class="row justify-content-center pt-3">
                <label
                  class="hoverable profile-input p-0 m-0"
                  style="width: 31%; height: 31%"
                  :for="`feed-logo-file`"
                >
                  <img
                    v-if="imgSrc != null"
                    :src="imgSrc"
                    class="img-fluid rounded-circle selected-logo w-100 h-100"
                  />
                  <img
                    v-if="!imgSrc"
                    src="../../assets/old_logo.png"
                    class="img-fluid rounded-circle w-100 h-100 object-fit-cover"
                  />
                  <div class="hover-text">Choose file</div>
                  <div class="background"></div>
                </label>
                <br />
                <input
                  class="fileInput"
                  :id="`feed-logo-file`"
                  :data-feed="feeds.id"
                  type="file"
                  v-on:change="readURL($event)"
                  accept="image/*"
                />
                <div class="mt-4 mb-2">
                  <p
                    class="text-dark mx-auto border-bottom border-2 border-dark m-0 h3"
                    style="width: fit-content; font-size: 12px"
                  >
                    Background Color Behind Logo (Optional)
                  </p>
                </div>
                <div
                  class="d-flex align-items-center justify-content-center pt-3 flex-wrap"
                >
                  <label for="head" class=""
                    ><strong>Pick Color</strong> :&nbsp;&nbsp;
                  </label>
                  <input
                    type="color"
                    id="head"
                    name="head"
                    v-model="colour"
                    class="form-control form-control-color p-0 border-dark"
                  />
                </div>
                <small class="text-center py-2"
                  >This background colour will be only applied to image's with
                  logo on bottom.</small
                >
              </div>
              <div class="card-footer col-12 border-0 mt-3 text-center py-2">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm me-3"
                  data-bs-dismiss="modal"
                  :class="isLoad ? 'disabled' : ''"
                  @click="cancelImageUpload()"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  :class="isLoad ? 'disabled' : ''"
                  class="btn btn-success btn-sm"
                  :disabled="!selectedFile"
                  @click="uploadImage()"
                >
                  Upload
                  <span
                    v-if="isLoad"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "jquery-ui";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import CustomFilter from "./customfilter.vue";
import Card from "./card.vue";
import Loading from "vue-loading-overlay";
//Import stylesheet

import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
export default {
  name: "Dashboard",
  components: {
    CustomFilter,
    Loading,
    Card,
    vSelect,
  },
  computed: {
    ...mapGetters(["feedsList"]),
    displayedRecords() {
      // const startIndex = this.perPage * (this.page - 1);
      // const endIndex = startIndex + this.perPage;
      // console.log(this.feedsList)
      this.feedsType = this.feedsList?.filter_type;
      this.total = parseInt(this.feedsList?.feeds_data?.total);
      this.lastPage = parseInt(this.feedsList?.feeds_data?.last_page);
      this.feeds = this.feedsList?.feeds_data?.data
        ? this.feedsList?.feeds_data?.data
        : [];
      return this.feedsList?.feeds_data?.data;
    },
  },
  watch: {
    perPage: function (val, oldVal) {
      this.paginateDisable = true;
      this.page = 1;
      this.perPage = parseInt(val);
      var data = {
        page: this.page,
        perPage: this.perPage,
      };
      this.filterData.page = 1;
      this.filterData.perPage = parseInt(val);
      this.fetchfeeds(this.filterData).then((response) => {
        this.paginateDisable = false;
      });
      //displayedRecords();
    },
  },
  data() {
    return {
      feedsType: "",
      error: false,
      order: "DESC",
      uploadId: {},
      total_copy_rem_tmp: 0,
      refresh: 0,
      totalChunk: 15,
      orderby: "date",
      loading: false,
      paginateDisable: false,
      filterData: {},
      page: 1,
      perPage: 10,
      feeds: [],
      lastPage: 0,
      total: 0,
      popupImgSrc: "",
      deleteId: "",
      cancelFeedObject: "",
      isCancel: false,
      colour: "#ffffff",
      selectedFile: null,
      load: false,
      imgSrc: null,
      LogoError: false,
      isLoad: false,
      length: "",
      uploadLogoFeed: "",
      uploadLogoShow: false,
      isImage: false,
      showObservanceDate: true,
      orderOptions: [
        {
          name: "low to high",
          value: "ASC",
        },
        {
          name: "high to low",
          value: "DESC",
        },
      ],
      orderByOptions: [
        {
          name: "submited date",
          value: "date",
        },
        {
          name: "observance date (when applicable)",
          value: "observance_date",
        },
        {
          name: "popularity",
          value: "used",
        },
      ],
      deleteFeedMsg:''
    };
  },
  created() {
    let st = localStorage.getItem("pay_status");
    if (st == 0) {
      this.$router.push("/payments/due");
    }
    this.loading = true;
    let selected=localStorage.getItem('type')

    var data = {
      type:selected?selected:"",
      order_by: "date",
      ordertype: "DESC",
      media_type: "",
      used: "",
      search: "",
      page: this.page,
      perPage: this.perPage,
      observance_start_day: "",
      observance_end_day: "",
      observance_start_month: "",
      observance_end_month: "",
    };
    var date = new Date();
    var curr_d = date.getDate();
    var curr_m = date.getMonth()+1;
    if(selected == 'events'){
      data.ordertype = "ASC";
      data.order_by = "";
      data.observance_start_day= curr_d,
      data.observance_end_day= "31",
      data.observance_start_month= curr_m,
      data.observance_end_month= "12",
      this.order = "ASC";
      this.orderby = "";
    }

    this.filterData = data;
    this.fetchfeeds(data)
      .then((response) => {
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        localStorage.clear();
        this.$router.push("/login");
      });
    this.checkUserData();
  },
  methods: {
    ...mapActions(["fetchfeeds", "deletefeeds"]),
    setId(obj) {
      this.uploadId = obj;
    },
    changeOrderByValues(type){
      this.showObservanceDate = true
      if(type == 'events'){
        this.showObservanceDate = false
      }
    },
    typeSelect(obj) {    
      this.order = obj.order;
      this.orderby = obj.orderby;
    },
    selectedOrderBy(event) {
      this.error = false;
      this.filterData.order_by = event.target.value;
      this.fetchfeeds(this.filterData)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSelect(event) {
      this.error = false;
      this.filterData.ordertype = event.target.value;
      this.fetchfeeds(this.filterData)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    openModal() {
      $(".modal-content").resizable({
        minHeight: 950,
        minWidth: 320,
      });
      $("#videoModal").on("show.bs.modal", function () {
        // $(".modal-content").css("width", "800");
        // $(".modal-content").css("height", "450");
        $(this).find(".modal-body").css({
          "max-height": "100%",
        });
      });
    },
    uploadLogo(feed_id) {
      this.refresh = this.refresh + 1;
      this.loading = true;
      this.SelectFilter(this.filterData);
    },
    SelectFilter(value) {
      this.error = false;
      this.loading = true;

      if (value) {
        this.filterData = value;
      }

      this.filterData.perPage = this.perPage;
      this.page = this.filterData.page;

      this.fetchfeeds(this.filterData)
        .then((response) => {
          this.loading = false;
          // console.log('click1',response)

          (this.orderby = this.filterData.order_by),
            (this.order = this.filterData.ordertype);
        })
        .catch((err) => {
          this.loading = false;
          // console.log('click',err)
          this.$toasted.show(`Something went wrong`, {
            theme: "bubble",
            type: "error",
            position: "top-center",
            duration: 1500,
            singleton: true,
          });
        });
    },
    paginationCallback(page) {
      //console.log(page)
      this.paginateDisable = true;

      (this.filterData.page = page), (this.filterData.perPage = this.perPage);

      this.fetchfeeds(this.filterData)
        .then((response) => {
          this.page = parseInt(page);
          this.paginateDisable = false;
        })
        .catch(() => {});
    },
    checkUserData() {
      axios
        .get(process.env.VUE_APP_BASEURL + "/get-user-data", {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.total_copy_rem_tmp = res?.data?.user_data?.quota_limit;
        })
        .catch(() => {});
    },
    showImgInPopup(event, id) {
      this.popupImgSrc = event.target.getAttribute("src");
      $("#showImgInPopup").modal("show");
    },
    deleteFeed(feed) {
      this.deleteFeedMsg = feed.is_scheduled ? 'If you delete this feed, it will be canceled and will not be posted as scheduled. Are you sure you want to delete this feed?' : ''
      this.deleteId = feed.id;
      $("#deleteFeedModal").modal("show");
    },
    DeleteConfirm() {
      let obj = {
        feed_id: this.deleteId,
      };
      axios
        .post(process.env.VUE_APP_BASEURL + `/delete-custom-feed`, obj, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status) {
            this.$toasted.show(res.data?.message ? res.data.message : `Feed Successfully Deleted`, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 4000,
              singleton: true,
            });
            this.SelectFilter(null);
          } else {
            this.$toasted.show(`${res.data.error_message ? res.data.error_message : res.data.message}`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 5000,
              singleton: true,
            });
          }
        })
        .catch((err) => {
          if(err.response.data && err.response.data.message ){
            this.$toasted.show(err.response.data.message, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2000,
              singleton: true,
            });
          }else{
          this.$toasted.show(`Server Error`, {
            theme: "bubble",
            type: "error",
            position: "top-center",
            duration: 2000,
            singleton: true,
          });
          }
        
        });
    },   
    cancelFeed(feeds) {
      this.cancelFeedObject = feeds;
      $("#cancelSchedule").modal("show");
    },
    cancelSchedule() {
      this.isCancel = this.cancelFeedObject.id;
      let obj = {
        feed_id: this.cancelFeedObject.id,
      };
      axios
        .post(process.env.VUE_APP_BASEURL + `/delete-schedule-feed`, obj, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
      .then(() => {
          this.isCancel = false;
          this.cancelFeedObject.scheduled_date = '';
          this.cancelFeedObject.is_scheduled = 0;
          this.$toasted.show(`Schedule Successfully Cancel`, {
            theme: "bubble",
            type: "success",
            position: "top-center",
            duration: 2000,
            singleton: true,
          });
        })
      .catch(() => {
        this.isCancel = false;
        this.$toasted.show(`Something Went Wrong`, {
          theme: "bubble",
          type: "error",
          position: "top-center",
          duration: 1500,
          singleton: true,
        });
      });
    },
    SchedulingFeed(feeds){ 
    },
    uploadFeedLogo(feeds){
      $(`#feed-logo-file`).val("");
      this.selectedFile = null
      this.imgSrc = null
      this.uploadLogoFeed = feeds
      $(`#Image-upload`).modal("show")
    },
    readURL(event) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        this.selectedFile = event.target.files[0];
        this.LogoError = false;
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    cancelImageUpload() {
      $(`#feed-logo-file`).val("");
      this.selectedFile = null;
      this.imgSrc = null;
      this.LogoError = false;
      this.colour = "#ffffff";
    },
    uploadImage() {
      this.uploadLogoShow = this.uploadLogoFeed.id;
      this.length = "25%";
      this.isLoad = true;
      let data = new FormData();
      // let feed_id = $('#feed-id-popup').val();
      this.isImage = this.uploadLogoFeed.id;
      data.append("file", this.selectedFile);
      data.append("single_feed", true);
      data.append("feed_id", this.uploadLogoFeed.id);
      data.append("color_code", this.colour);
      axios
        .post(process.env.VUE_APP_BASEURL + "/upload-user-logo", data, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.length = "50%";
          }, 1000);
          setTimeout(() => {
            this.length = "75%";
          }, 2000);
          setTimeout(() => {
            this.length = "100%";
          }, 3000);
          //console.log( this.refresh)
          setTimeout(() => {
            this.isLoad = false;
            this.length = "0%";
            this.selectedFile = null;
            //this.$router.go()
            this.uploadLogoShow = false;
            this.$toasted.show(`Logo uploaded successfully.`, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 1500,
              singleton: true,
            });
          }, 3200);
          setTimeout(() => {
            this.uploadLogoFeed.logo_status = 1;
            $(`#Image-upload`).modal("hide");
          }, 3600);
          setTimeout(() => {
            this.uploadLogoFeed.media.url = res?.data?.new_media_url;
            // $(`#feed-img-${feed_id}`).attr('src', res?.data?.new_media_url)
            this.isImage = false;
          }, 4000);
        })
        .catch((err) => {
          //console.log('errr',err)
          this.length = "0%";
          this.isLoad = false;
          this.selectedFile = null;
          this.isImage = false;
          if (err?.response?.data?.status == false) {
            this.$toasted.show(`${err?.response?.data?.error_message}`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          } else {
            this.$toasted.show(`Internal Server Error`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          }
          this.uploadLogoFeed.logo_status = 0;
          this.uploadLogoShow = false;
        });
    },
    LogoErrorToggle(val){
      this.LogoError = val
    }
  },
};
</script>

<style>
html,
body {
  font-size: 14px;
}

.lists {
  border: 1px solid black;
}

.desc_text {
  border: 1px solid black;
  background: lightgray;
}

.content-img {
  height: 170px !important;
  width: 260px !important;
}
.search_box {
  height: 80%;
}
nav {
  padding: 0px !important;
}
.no_data {
  margin-top: 200px;
  text-align: center;
}
.VuePagination__count {
  display: none !important;
}
.how_works {
  border-color: #4076ad !important;
  background-color: #2c6095 !important;
  text-decoration: none !important;
  color: #fff !important;
}

.video-tutorial-header {
  background: #354a5f;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  text-indent: 10px;
}
#videoModal > .modal-dialog {
  margin: -0.25rem auto !important;
}
label {
  font-size: 12px;
}
.pages_count {
  font-size: 12px;
}
.btn-cus-primary {
  background-color: #05cffdf2 !important;
  color: white !important;
  border-color: #05cffdf2 !important;
}

.page-item.active .page-link {
  z-index: 3;
  background-color: #05cffdf2 !important;
  color: white !important;
  border-color: #05cffdf2 !important;
}

.page-link {
  color: grey !important;
  padding: 0.375rem 0.6rem !important;
}
li.pagination-disabled,
.custom-pagination-disabled .page-item {
  cursor: not-allowed;
}
li.pagination-disabled a,
.custom-pagination-disabled .page-item a {
  pointer-events: none;
}
.icon-bold {
  -webkit-text-stroke: 1px;
}
.selected-logo {
  object-fit: contain;
  max-width: 100% !important;
  max-height: 149px;
  min-width: 149px;
  min-height: 149px;
}
</style>
