<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding container">
                <div class="row d-flex justify-content-center">
                    <div class="col-12">
                        <div class="d-flex justify-content-between position-relative">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <router-link class="nav-link" style="color: #0d6efd !important;" to="/business">
                                        Business
                                    </router-link>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active">
                                        Review Posting Setting
                                    </button>
                                </li>
                            </ul>
                            <div class="position-absolute end-0 back-to-account-btn">
                                <router-link to="/profile" class="btn-sm btn btn-primary">
                                    <!-- v-tooltip="'Manage Profile'" -->
                                    <i class="bi bi-caret-left-fill"></i>
                                    Back To My Account
                                </router-link>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="card user-card-full">
                                    <div class="d-flex justify-content-between mt-3 ms-3">
                                        <h6 class="mt-2 pb-2 f-w-600">Review Posting Setting</h6 >
                                        <button class="btn btn-primary btn-sm mb-3 me-3 d-none">Manage Profile</button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr class="m-0">
                                        </div>
                                    </div>
                                    <div class="row m-2">
                                        <div class="col-md-12">
                                            <label class="form-check-label mb-0 f-w-600" for="review_post_status">
                                                Review Auto Post 
                                            </label>
                                            <div class="form-check form-switch">
                                                <input @change="updatePostStatus" id="review_post_status" type="checkbox" v-model="auto_post_status" class="form-check-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="auto_post_status">
                                        <div class="row m-2 mb-1 d-flex justify-content-center">
                                            <label for="" class="form-label">Templates</label>
                                            <div 
                                                class="col-sm-12 col-md-4 col-lg-2 mb-4 mt-4 template-div"
                                                v-for="(template, index) in  templates"
                                                >
                                                <small class="selected-text" v-if="template.id == formFields.template_id"><strong>Selected</strong></small>
                                                <div
                                                    :class="template.id == formFields.template_id ? 'active' : ''" 
                                                    class="template-card">
                                                    <img 
                                                        @click="() => { formFields.template_id = template.id }"
                                                        :src="template.url" class="w-100 mb-3">
                                                </div>
                                                <a href="javascript:;" @click="() => { templatePreview(template.id) }" class="preview_link">Preview</a>
                                            </div>
                                        </div>
                                        <div class="row m-2 ">
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="color" class="form-label">Background Color</label>
                                                    <input type="color" id="color" class="form-control" v-model="formFields.backgroundcolor" />
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="mb-2 col-md-3 d-none">
                                                        <div class="mb-3">
                                                            <label for="color" class="form-label">Post Scheduled Monthly/Weekly ?</label>
                                                            <v-select 
                                                                :options="scheduleType"
                                                                placeholder="Please Select"
                                                                v-model="formFields.schedule_type"
                                                                :reduce="scheduleType => scheduleType.value"
                                                                :class="$v.formFields.schedule_type.$error ? 'is-invalid' : ''"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="mb-2 col-md-3" v-if="formFields.schedule_type == 'week'">
                                                        <div class="mb-1" >
                                                            <label for="week_day" class="form-label">Post Schedule Week Day</label>
                                                            <v-select 
                                                                :clearable="false"
                                                                id="week_day"
                                                                v-model="formFields.schedule_week_day"
                                                                :reduce="weekDays => weekDays.value"
                                                                :options="weekDays"
                                                                :class="$v.formFields.schedule_week_day.$error ? 'is-invalid' : ''"
                                                                placeholder="Please Select"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="mb-2 col-md-3" v-if="formFields.schedule_type == 'month'">
                                                        <div class="mb-1 month_day_select" >
                                                            <label for="color" class="form-label">Month Date</label>
                                                            <v-select 
                                                                v-model="formFields.schedule_month_date"
                                                                :options="monthsDates"
                                                                :class="$v.formFields.schedule_month_date.$error ? 'is-invalid' : ''"
                                                                placeholder="Please Select"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div class="mb-2 col-md-3">
                                                        <div class="d-flex justify-content-center">
                                                            <div style="width:95%" class="mb-1 hour_select" v-if="(formFields.schedule_type == 'week' && formFields.schedule_week_day) || (formFields.schedule_type == 'month' && formFields.schedule_month_date)">
                                                                <label for="cron_hour" class="form-label">Post Schedule Hour</label>
                                                                <v-select 
                                                                    :clearable="false"
                                                                    id="cron_hour"
                                                                    v-model="formFields.schedule_hour"
                                                                    :reduce="hours => hours.value"
                                                                    :options="hours"
                                                                    :class="$v.formFields.schedule_hour.$error ? 'is-invalid' : ''"
                                                                    placeholder="Please Select"
                                                                    />
                                                            </div>
                                                            <div class="mt-4 ms-2 pt-2" v-if="totalFeeds > 0">
                                                                <i class="bi bi-info-circle mt-4" v-tooltip="`Total Feeds To Schedule: ${totalFeeds}`"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-2 col-md-12 mt-0 note">
                                                        <small class="text-danger">Note: {{review_note}}</small>
                                                    </div>
                                                    <div class="col-md-12 mb-3">
                                                        <label class="form-check-label" for="connected_social_platforms">Post Schedule for connected social platforms:</label>
                                                        <br>
                                                        <template v-if="userInfo.ayr_connected_accounts && userInfo.ayr_connected_accounts.length > 0">
                                                            
                                                            <div class="d-flex align-items-center flex-wrap platforms">
                                                                <div class="form-check" v-for="(item, index) of userInfo.ayr_connected_accounts" :key="index">
                                                                    <div v-if="item == 'facebook'" v-tooltip="'Facebook Page'" class="selection facebook">
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                                                    <path  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'instagram'" v-tooltip="'Instagram'" class="selection instagram">
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'pinterest'" v-tooltip="'Pinterest'" class="selection pinterest">
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pinterest" viewBox="0 0 16 16" >
                                                                                    <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'twitter'" v-tooltip="'Twitter'" class="selection twitter" >
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16" >
                                                                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'linkedin'" v-tooltip="'Linkedin'" class="selection linkedin" >
                                                                        <label> 
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                                                                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'gmb'" v-tooltip="'Google My Bussiness'" class="selection google">
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
                                                                                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'telegram'" class="selection telegram" v-tooltip="'Telegram'">
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                                                                </svg>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div v-if="item == 'fbg'" v-tooltip="'Facebook Group'" class="selection fb-group" >
                                                                        <label>
                                                                            <input type="checkbox" :value="item" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(item)) : false"/>
                                                                            <span>
                                                                                <img src="../../../assets/fb-group.png" class="fb-img" alt=""/>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mt-1 ms-2 me-4 form-check form-check-inline d-none" v-for="platforms in userInfo.ayr_connected_accounts">
                                                                <input class="form-check-input" type="checkbox" @change="onPlatformSelection($event)" :checked="formFields.social_accounts && formFields.social_accounts.length > 0 ? (formFields.social_accounts.includes(platforms)) : false" :id="platforms" :value="platforms"/>
                                                                <label class="form-check-label" :for="platforms">{{ $ucFirst(platforms) }}</label>
                                                            </div>
                                                        </template>
                                                        <small v-else class="text-danger">Connected Social Accounts Not Found</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <div class="d-flex justify-content-start">
                                                    <div class="d-grid gap-2">
                                                        <button type="button" :disabled="isLoading" @click="updatePostSettings" class="btn-sm btn btn-primary">
                                                            Save
                                                            <Loading v-if="isLoading" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="previewModal">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Preview
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
                    <div class="modal-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-center iframe-loader" style="height: 800px; align-items :center;">
                                    <span class="spinner-border spinner-border mx-1 text-primary" role="status" aria-hidden="true"></span>
                                </div>
                                <iframe 
                                    width="100%"
                                    height="800px"
                                    frameborder="0"
                                    :src="iframeSrc" 
                                    id="preview_template"
                                >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '../reviews/loading.vue';

export default {
    name: 'PostTemplates',
    components: {
        vSelect,
        Loading,
    },
    data:function() {
        return {
            iframeSrc:'',
            isLoading:false,
            templates:[],
            defaultSettings:{},
            formFields:{
                template_id:'',
                backgroundcolor:'',
                schedule_type:'week',
                schedule_week_day:'',
                schedule_month_date:'',
                schedule_hour:'',
                social_accounts:[],
                status:'',
            },
            scheduleType:[
                {
                    label:'Monthly',
                    value:"month"
                },
                {
                    label:'Weekly',
                    value:"week"
                }
            ],
            weekDays:[
                {
                    label:'Sunday',
                    value:'sunday'
                },
                {
                    label:'Monday',
                    value:'monday'
                },
                {
                    label:'Tuesday',
                    value:'tuesday'
                },
                {
                    label:'Wednesday',
                    value:'wednesday'
                },
                {
                    label:'Thursday',
                    value:'thursday'
                },
                {
                    label:'Friday',
                    value:'friday'
                },
                {
                    label:'Saturday',
                    value:'saturday'
                }
            ],
            hours:[
                {
                    label:'12:00 AM',
                    value:'00:00'
                },
                {
                    label:'01:00 AM',
                    value:'01:00'
                },
                {
                    label:'02:00 AM',
                    value:'02:00'
                },
                {
                    label:'03:00 AM',
                    value:'03:00'
                },
                {
                    label:'04:00 AM',
                    value:'04:00'
                },
                {
                    label:'05:00 AM',
                    value:'05:00'
                },
                {
                    label:'06:00 AM',
                    value:'06:00'
                },
                {
                    label:'07:00 AM',
                    value:'07:00'
                },
                {
                    label:'08:00 AM',
                    value:'08:00'
                },
                {
                    label:'09:00 AM',
                    value:'09:00'
                },
                {
                    label:'10:00 AM',
                    value:'10:00'
                },
                {
                    label:'11:00 AM',
                    value:'11:00'
                },
                {
                    label:'12:00 PM',
                    value:'12:00'
                },
                {
                    label:'01:00 PM',
                    value:'13:00'
                },
                {
                    label:'02:00 PM',
                    value:'14:00'
                },
                {
                    label:'03:00 PM',
                    value:'15:00'
                },
                {
                    label:'04:00 PM',
                    value:'16:00'
                },
                {
                    label:'05:00 PM',
                    value:'17:00'
                },
                {
                    label:'06:00 PM',
                    value:'18:00'
                },
                {
                    label:'07:00 PM',
                    value:'19:00'
                },
                {
                    label:'08:00 PM',
                    value:'20:00'
                },
                {
                    label:'09:00 PM',
                    value:'21:00'
                },
                {
                    label:'10:00 PM',
                    value:'22:00'
                },
                {
                    label:'11:00 PM',
                    value:'23:00'
                }
            ],
            monthsDates:[],
            business_id:'',
            auto_post_status:'',
            review_note:'',
            totalFeeds:0,
            userInfo:{}
        }
    },
    created:async function() {
        
        /* $('body').on('click', 'div.template-card', function(){
            let that = $(this)
            $('body').find('div.template-card').removeClass('active')
            that.addClass('active')
        }) */

        $('body').on('click','a.preview_link', function(){
            $('body').find('#previewModal').modal('show')
        })

        this.fetchUserBusiness().then((resp) => {
            if(resp.status)
            {
                this.business_id = resp.data.id   
                this.auto_post_status = resp.data.auto_post_status   
            }
        })

        await this.getPostTemplates().then((resp) => {
            if(resp.status)
            {
                this.templates = resp.data
                this.userInfo = resp.user
                /* if(this.userInfo.ayr_connected_accounts && this.userInfo.ayr_connected_accounts.length > 0)
                {
                    if(!this.formFields.social_accounts || this.formFields.social_accounts.length == 0 )
                    {
                        this.formFields.social_accounts = [
                            ...this.userInfo.ayr_connected_accounts
                        ]
                    }
                } */
            }
        })

        await this.getUserPostSetting().then( async (resp) => {
            if(resp)
            {
                let userAccounts = [];
                if(this.userInfo.ayr_connected_accounts && this.userInfo.ayr_connected_accounts.length > 0)
                {
                    if(!this.formFields.social_accounts || this.formFields.social_accounts.length == 0 )
                    {
                        userAccounts = [
                            ...this.userInfo.ayr_connected_accounts
                        ]
                    }
                }

                this.formFields = {
                    ...this.formFields,
                    id:resp.id,
                    template_id:resp.template_id,
                    backgroundcolor:resp.backgroundcolor,
                    status:resp.status,
                    schedule_type:resp.schedule_type,
                    schedule_hour:resp.schedule_hour,
                    schedule_month_date:resp.schedule_month_date,
                    schedule_week_day:resp.schedule_week_day,
                    social_accounts:resp.social_accounts ? resp.social_accounts : userAccounts,
                }
                this.totalFeeds = resp.feedCount
                await this.getReviewPostNote(resp.schedule_week_day, resp.schedule_hour)
            }
        })

        let settingsField = [
            'default_review_post_template_id',
            'default_review_post_schedule_type',
            'default_review_post_schedule_hour',
            'default_review_post_background_color',
            'default_review_post_schedule_week_day',
            'default_review_post_schedule_month_date'
        ]

        this.getSetting(settingsField).then((resp) => {
            this.defaultSettings = resp
            if(!this.formFields.backgroundcolor)
            {
                this.formFields.backgroundcolor = this.defaultSettings.default_review_post_background_color
            }

            if(!this.formFields.template_id)
            {
                this.formFields.template_id = this.defaultSettings.default_review_post_template_id
            }

            if(!this.formFields.schedule_type)
            {
                this.formFields.schedule_type = this.defaultSettings.default_review_post_schedule_type
            }

            if(!this.formFields.schedule_week_day)
            {
                this.formFields.schedule_week_day = this.defaultSettings.default_review_post_schedule_week_day
            }

            if(!this.formFields.schedule_month_date)
            {
                this.formFields.schedule_month_date = this.defaultSettings.default_review_post_schedule_month_date
            }

            if(!this.formFields.schedule_hour)
            {
                this.formFields.schedule_hour = this.defaultSettings.default_review_post_schedule_hour
            }

            if(!this.review_note)
            {
                this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
            }

        })

        for (let index = 1; index <= 31; index++) {
            this.monthsDates.push(index)            
        }
    },
    validations: {
        formFields:{
            template_id:{
                required
            },
            backgroundcolor:{
                required
            },
            schedule_type:{
                required
            },
            status:{
                required
            },
            schedule_week_day:{},
            schedule_month_date:{},
            schedule_hour:{},
        }
    },
    methods: {
        ...mapActions([
            'getSetting',
            'getReviewNote',
            'getPostTemplate',
            'getPostTemplates',
            'getUserPostSetting',
            'updateUserPostSetting',
            'fetchUserBusiness',
            'updateUserBusinessPostStatus',
        ]),
        updatePostSettings:function(){
            if(this.formFields.social_accounts && this.formFields.social_accounts.length > 0)
            {
                this.isLoading = true
                this.updateUserPostSetting(this.formFields).then((resp) => {
                    if(resp.status)
                    {
                        this.formFields = {
                            ...this.formFields,
                            id:resp.data.id,
                            template_id:resp.data.template_id,
                            backgroundcolor:resp.data.backgroundcolor,
                            status:resp.data.status,
                            schedule_type:resp.data.schedule_type,
                            schedule_hour:resp.data.schedule_hour,
                            schedule_month_date:resp.data.schedule_month_date,
                            schedule_week_day:resp.data.schedule_week_day,
                            social_accounts:resp.data.social_accounts
                        }
                        this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
                        this.notify(resp.message)   
                    }
                    else
                    {
                        this.notify(resp && resp.message ? resp.message : 'Something went wrong please try later', 'danger')   
                    }
                    this.isLoading = false
                })
            }
            else
            {
                this.notify('Please select atleast one social media platform.','danger')
            }
        },
        updatePostStatus:function(e){
            let checked = e.target.checked
            this.updateUserBusinessPostStatus({
                id:this.business_id,
                auto_post_status:this.auto_post_status,
            }).then((resp) => {
                if(resp.status)
                {
                    if(resp.business)
                    {
                        if(resp.business.auto_post_status)
                        {
                            this.getReviewPostNote(this.formFields.schedule_week_day, this.formFields.schedule_hour)
                        }
                    }   
                }
            })
        },
        notify: function(msg, type = 'success'){
            this.$toasted.show(msg, {
                theme: 'bubble',
                type: type,
                position: 'top-center',
                duration: 2500,
                singleton: true
            })
        },
        templatePreview:function(id){
            let iframe       = $('iframe#preview_template') 
            let iframeLoader = $('.iframe-loader');
            
            iframe.css('opacity',0);
            iframeLoader.removeClass('d-none')
            iframe.on('load',function(){
                setTimeout(function(){
                    iframe.css('opacity',1);
                    iframeLoader.addClass('d-none')
                },500)
            })
            this.iframeSrc = '';
            let url = process.env.VUE_APP_BASEURL
            let bgColor = this.formFields.backgroundcolor
            bgColor = bgColor ? bgColor.replace('#','') : '';
            this.iframeSrc = `${url}/review-to-image/preview?templateId=${id}&bgColor=${bgColor}&flag=${Math.random()}`;
            
        },
        getReviewPostNote:function(weekday, time){
            this.getReviewNote({weekday,time})
            .then((resp) => {
                if(resp.status)
                {
                    this.review_note = resp.note
                }
                else
                {
                    this.review_note = '';
                }
            })
        },
        onPlatformSelection:function(e){
            let {checked, value} = e.target
            if(checked)
            {
                this.formFields.social_accounts.push(value)   
            }
            else
            {
                if(this.formFields.social_accounts && this.formFields.social_accounts.length > 0)
                {
                    let index = this.formFields.social_accounts.indexOf(value)
                    if(index >= 0)
                    {
                        this.formFields.social_accounts.splice(index,1);
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
    min-height: 300px;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.template-card{
    padding:1%;
    width: 200px;
}
.template-card.active{
    border-color: black;
    border-width: 3px;
    border-style: solid;
}

div.month_day_select ul,
div.hour_select ul {
    height: 200px !important;
}

.preview_link {
    position: absolute;
    left: 35%;
    top: 100%;
}

.selected-text {
    position: absolute;
    top: -15%;
    left: 35%;
}

.template-div {
    position: relative;
    cursor: pointer;
}

div.selection label{
    cursor: pointer
}

.back-to-account-btn{
    top: 0;
}

@media screen and (max-width: 768px) {
    .selected-text {
        top: -10%;
        left: 44%;
    }

    .preview_link {
        top: 100%;
        left: 44%;
    }
}

@media screen and (max-width: 540px) {
    .back-to-account-btn {
        top:-29px !important
    }

    .preview_link {
        top: 86%;
        left: 42%;
    }    

    iframe#preview_template{
        height: 600px;
    }
}
.template-div{
    display: flex;
    justify-content: center;
}
</style>