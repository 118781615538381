<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding container">
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-12 col-md-12">
                        <div class="d-flex justify-content-between position-relative">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" type="button">
                                        Business
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation" v-tooltip="!formFields.id ?'Please Add Business Details to Enable This Option':''" :style="[formFields.id ? '' : {'cursor':'not-allowed'}]">
                                    <router-link 
                                        class="nav-link"
                                        :disabled="formFields.id ? false : true"
                                        style="color: #0d6efd !important;"
                                        to="/post-templates">
                                        Review Posting Setting
                                    </router-link>
                                </li>
                            </ul>
                            <div class="position-absolute end-0 back-to-account-btn">
                                <router-link to="/profile"  class="btn-sm btn btn-primary">
                                    <!-- v-tooltip="'Manage Profile'" -->
                                    <i class="bi bi-caret-left-fill"></i>
                                    Back To My Account
                                </router-link>
                            </div>
                        </div>
                        
                        <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="card user-card-full">
                                    <div class="d-flex justify-content-between mt-3 ms-3">
                                        <h6 class="mt-2 pb-2 f-w-600">
                                            {{ bool ? 'Business View' : (formFields.id ? 'Update Business' : 'Add Business')}}
                                        </h6>
                                        <div class="heading-right" v-if="bool">
                                            <router-link 
                                                to="/profile"
                                                v-tooltip="'Manage Profile'"
                                                class="d-none btn-sm btn btn-primary px-2 py-1 mx-2"
                                            >
                                                Manage Profile
                                            </router-link>
                                            <a
                                                @click="deleteBusiness"
                                                v-tooltip="'Delete Business'"
                                                v-if="formFields.id && formFields.verified != 0"
                                                class="btn-sm btn btn-danger px-2 py-1 mx-2"
                                            >
                                            <i class="bi bi-trash3 fs-6"></i>
                                            </a>
                                            <a
                                                @click="toggleField"
                                                v-tooltip="'Edit Business'"
                                                v-if="formFields.id && formFields.verified != 0"
                                                class="btn-sm btn btn-primary px-2 py-1 mx-2"
                                            >
                                            <i class="bi bi-pencil-square fs-6"></i>
                                            </a>
                                            <a
                                                v-tooltip="'View Business'"
                                                class="d-none btn-sm btn btn-primary px-2 py-1 mx-2 active"
                                            >
                                                <i class="bi bi-eye"></i>
                                            </a>
                                        </div>
                                        <div class="heading-right" v-if="!bool">
                                            <a
                                                v-if="formFields.id"
                                                @click="toggleField"
                                                v-tooltip="'View Business'"
                                                class="btn btn-sm btn-primary px-2 py-1 mx-2 "
                                            >
                                                <i class="bi bi-eye"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr class="m-0">
                                        </div>
                                    </div>
                                    <div class="row gx-0">
                                        <div class="col-lg-12">
                                            <form @submit.prevent="submitForm">
                                                <div class="card-block position-relative">
                                                    <div class="row" v-if="bool">
                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                Name:
                                                            </p>
                                                            <p class="mb-4">
                                                                {{ formFields.name }}
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                Country:
                                                            </p>
                                                            <p class="mb-4">
                                                                {{ formFields.country }}
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                City:
                                                            </p>
                                                            <p class="mb-4">
                                                                {{ formFields.city }}
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                Postal Code:
                                                            </p>
                                                            <p class="mb-4">
                                                                {{ formFields.postal_code }}
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                Platforms:
                                                            </p>
                                                            <p class="mb-4">
                                                                <template v-for="platform in formFields.reviews_from">
                                                                    <span class="badge bg-success m-1">{{ platform }}</span>
                                                                </template>
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6 d-none">
                                                            <p class="mb-0 f-w-600">
                                                                Status:
                                                            </p>
                                                            <p class="mb-4">
                                                                <span class="badge bg-success" v-if="formFields.status">Active</span>
                                                                <span class="badge bg-danger" v-if="!formFields.status">In-Active</span>
                                                            </p>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <p class="mb-0 f-w-600">
                                                                Verify Status:
                                                            </p>
                                                            <p class="mb-4">
                                                                <span class="badge bg-info" v-if="formFields.verified == 0">Verifying..</span>
                                                                <span class="badge bg-success" v-if="formFields.verified == 1">Verified</span>
                                                                <span class="badge bg-danger" v-if="formFields.verified == 2">Unverified</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="row" v-if="!bool">
                                                        <div class="col-sm-6 mb-3">
                                                            <label for="name" class="mb-0 f-w-600">
                                                                Name: <span class="required">*</span>
                                                            </label>
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                :disabled="bool || formFields.verified == 1 ? true : false"
                                                                class="form-control"
                                                                placeholder="Enter Name"
                                                                v-model="formFields.name"
                                                                :class="$v.formFields.name.$error ? 'is-invalid' : ''"
                                                            />
                                                        </div>

                                                        <div class="col-sm-6 mb-3">
                                                            <label for="country" class="mb-0 f-w-600">
                                                                Country: <span class="required">*</span>
                                                            </label>
                                                            <v-select
                                                                id="country"
                                                                :disabled="bool || formFields.verified == 1 ? true : false"
                                                                label="label"
                                                                :options="countries"
                                                                placeholder="Select Country"
                                                                v-model="formFields.country"
                                                                :reduce="countries => countries.value"
                                                                :class="$v.formFields.country.$error ? 'is-invalid' : ''"
                                                            >
                                                            </v-select>
                                                        </div>

                                                        <div class="col-sm-6 mb-3">
                                                            <label for="city" class="mb-0 f-w-600">
                                                                City: <span class="required">*</span>
                                                            </label>
                                                            <input
                                                                id="city"
                                                                type="text"
                                                                :disabled="bool || formFields.verified == 1 ? true : false"
                                                                class="form-control"
                                                                placeholder="Enter City"
                                                                v-model="formFields.city"
                                                                :class="$v.formFields.city.$error ? 'is-invalid' : ''"
                                                            />
                                                        </div>

                                                        <div class="col-sm-6 mb-3">
                                                            <label for="postal_code" class="mb-0 f-w-600">
                                                                Postal Code: <span class="required">*</span>
                                                            </label>
                                                            <input
                                                                id="postal_code"
                                                                type="text"
                                                                :disabled="bool || formFields.verified == 1 ? true : false"
                                                                class="form-control"
                                                                placeholder="Enter Postal Code"
                                                                v-model="formFields.postal_code"
                                                                :class="$v.formFields.postal_code.$error ? 'is-invalid' : ''"
                                                            />
                                                        </div>

                                                        <div class="col-sm-6 mb-3">
                                                            <label for="platforms" class="mb-0 f-w-600">
                                                                Platforms: <span class="required">*</span>
                                                            </label>
                                                            <v-select
                                                                id="platforms"
                                                                :disabled="bool"
                                                                :multiple="true"
                                                                :options="platforms"
                                                                placeholder="Select Platforms "
                                                                v-model="formFields.reviews_from"
                                                                :class="$v.formFields.reviews_from.$error ? 'is-invalid' : ''"
                                                            >
                                                            </v-select>
                                                        </div>

                                                        <div class="col-sm-6 mb-3 d-none">
                                                            <label class="form-check-label mb-0 f-w-600" for="status">
                                                                Status
                                                            </label>
                                                            <div class="form-check form-switch">
                                                                <input
                                                                    id="status"
                                                                    type="checkbox"
                                                                    :disabled="bool"
                                                                    v-model="formFields.status"
                                                                    class="form-check-input"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-12 mb-3">
                                                            <div class="d-flex justify-content-end">
                                                                <button type="button" @click="cancel" class="m-2 btn btn-success btn-sm">Cancel</button>
                                                                <button :disabled="loading" type="submit" class="m-2 btn btn-primary btn-sm">
                                                                    {{ this.formFields.id ? 'Update' : 'Save' }}
                                                                    {{ formFields.verified != 1 ? ' & Verify ' : ''  }}
                                                                    <span
                                                                        v-if="loading"
                                                                        class="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
    name: 'Business',
    components: {
        vSelect,
    },
    data() {
        return {
            bool: true, 
            loading: false,
            formFields: {
                id: '',
                name: '',
                city: '',
                status: '',
                country: '',
                postal_code: '',
                reviews_from: '',
                verified: '',
            },
            countries: [
                { label: "Canada", value: "CAN" },
                { label: "Australia", value: "AUS" },
                { label: "United States", value: "USA" },
                { label: "United Kingdom", value: "GBR" },
                { label: "Germany", value: "DEU" },
                { label: "France", value: "FRA" },
                { label: "Japan", value: "JPN" },
                { label: "India", value: "IND" },
                { label: "Brazil", value: "BRA" },
                { label: "China", value: "CHN" },
                { label: "Mexico", value: "MEX" },
                { label: "South Africa", value: "ZAF" },
                { label: "Italy", value: "ITA" },
                { label: "Spain", value: "ESP" },
                { label: "Russia", value: "RUS" },
                { label: "Netherlands", value: "NLD" },
                { label: "Sweden", value: "SWE" },
                { label: "Norway", value: "NOR" },
                { label: "Switzerland", value: "CHE" },
                { label: "South Korea", value: "KOR" },
                { label: "Turkey", value: "TUR" },
            ],
            platforms:[],
            intervalId:'',
            businessId:''
        }
    },
    created() {
        this.fetchUserBusiness().then((resp) => {
            if(resp && resp.status)
            {
                let {data} = resp
                this.formFields = {
                    id:data.id,
                    name:data.name,
                    city: data.city,
                    status: data.status,
                    country: data.country,
                    postal_code: data.postal_code,
                    verified: data.verified,
                    reviews_from: []
                }

                if(data.reviews_from)
                {
                    data.reviews_from.map((item) => {
                        this.formFields.reviews_from.push(item.charAt(0).toUpperCase() + item.slice(1))
                    })
                }

                if(data.verified == 0)
                {
                    this.businessId = data.id
                    this.startInterval();
                }
            }
            else
            {
                this.bool = !this.bool
                console.error(resp.message)
            }
        })

        this.getSetting('social_platforms').then((resp) => {
            this.platforms = [];
            if(resp.social_platforms)
            {
                resp.social_platforms.map((item) => {
                    this.platforms.push(item.charAt(0).toUpperCase() + item.slice(1))
                })
            }
        })
    },
    validations: {
        formFields: {
            name: {required},
            city: {required},
            country: {required},
            postal_code: {required},
            reviews_from: {required}
        }
    },
    methods: {
        ...mapActions([
            'getSetting',
            'addUserBusiness',
            'fetchUserBusiness',
            'updateUserBusiness',
            'deleteUserBusiness'
        ]),
        submitForm: function(e) {
            e.preventDefault()
            this.loading = true
            
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid)
            {
                let promise;
                if(this.formFields.id)
                {
                    promise = this.updateUserBusiness(this.formFields)   
                }
                else 
                {
                    promise = this.addUserBusiness(this.formFields)   
                }

                if(promise)
                {
                    promise.then((resp) => {
                        if(resp)
                        {
                            if(resp.status)
                            {
                                this.bool = !this.bool 
                                this.notify(resp.message)
                                if(resp.business)
                                {
                                    this.formFields = {
                                        ...resp.business,
                                        reviews_from:[]
                                    }

                                    if(resp.business.reviews_from)
                                    {
                                        resp.business.reviews_from.map((item) => {
                                            this.formFields.reviews_from.push(item.charAt(0).toUpperCase() + item.slice(1))
                                        })
                                    }

                                    if(resp.business.verified != 1)
                                    {
                                        this.businessId = resp.business.id
                                        this.startInterval()
                                    }
                                }
                            }
                            else
                            {
                                this.notify(resp.message, 'danger')
                            }
                        }
                        else
                        {
                            this.notify("Something went wrong please try in some time",'danger')
                        }
                    })
                }
            }

            this.loading = false
        },
        toggleField: function() {
            this.bool = !this.bool  
        },
        cancel: function() {
            if(this.formFields.id)
            {
                this.bool = !this.bool   
            }
            else
            {
                this.$router.push('/profile')
            }
        },        
        startInterval:function(){
            if(this.intervalId)
            {
                clearInterval(this.intervalId)
            }
            this.intervalId = setInterval(this.intervalRefreshBusinessStatus, 5500);
        },
        intervalRefreshBusinessStatus:function(){
            if(this.businessId)
            {
                this.$helper.getRequest(`users/business/${this.businessId}/fetch-verified-status`).then((resp) => {
                    if(resp.status)
                    {
                        this.formFields.verified = resp.data.verified
                        if(resp.data.verified != 0)
                        {
                            this.businessId = '';
                        }
                    }
                })
            }
            else
            {
                if(this.intervalId)
                {
                    clearInterval(this.intervalId)
                }
            }
        },
        notify: function(msg, type = 'success'){
            this.$toasted.show(msg, {
                    theme: 'bubble',
                    type: type,
                    position: 'top-center',
                    duration: 2500,
                    singleton: true,
                })
        },
        deleteBusiness:function(){
            if(confirm("Are You want to delete the business ? it will also delete your synced reviews!"))
            {
                this.deleteUserBusiness(this.formFields.id).then((resp) => {
                    if(resp?.status)
                    {
                        this.formFields = {
                            id: '',
                            name: '',
                            city: '',
                            status: '',
                            country: '',
                            postal_code: '',
                            reviews_from: [],
                            verified: '',   
                        }
                        this.notify(resp.message)
                        this.toggleField()
                    }
                    else
                    {
                        this.notify(resp?.message ? resp.message : "Something went wrong please try after some time", "danger")
                    }
                })   
            }
        }
    }
}
</script>

<style>
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.back-to-account-btn{
    top: 0;
}

@media screen and (max-width: 540px) {
    .back-to-account-btn {
        top:-29px !important
    }
}
</style>